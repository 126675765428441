import { Ad } from '@/Redux/Slices/addCampaign';

export const networkOptions = [
	{ label: 'Taboola', value: '1' },
	// { label: 'Outbrain', value: '2' },
	{ label: 'RevContent', value: '3' },
];

export function parseCreativesPayload(ads: Ad[]) {
	return ads.map(ad => {
		return {
			usm_id: ad.usmID,
			network_id: ad.network,
			creative_id: ad.uuid,
			title: ad.headline,
			image_url: ad.image,
			thumbnail_url: ad.thumbnail,
			dicer_file: ad.dicerFile,
			file_type: ad.fileType,
			description: ad.description,
			cta: ad.callToAction,
			keywords: JSON.stringify(ad.keywords || []),
		};
	});
}

export function parseCreativesResponse(response: Record<string, any>[]): (Ad & { searchTerm: string })[] {
	return response.map(creative => {
		return {
			uuid: crypto.randomUUID(),
			creativeId: creative.uuid,
			createdAt: creative.created_at,
			usmID: creative.usm_id,
			network: creative.network_id,
			headline: creative.title,
			image: creative.image_url,
			thumbnail: creative.thumbnail_url || '',
			dicerFile: creative.dicer_file || '',
			fileType: creative.file_type,
			description: creative.description || '',
			callToAction: creative.cta || 'NONE',
			keywords: JSON.parse(creative.keywords || '[]'),
			searchTerm: `${creative.title} ${creative.description} ${creative.cta} ${creative.keywords} ${creative.uuid}`,
		} satisfies Ad & { searchTerm: string };
	});
}
