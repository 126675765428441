import { useState } from 'react';
import uniq from 'lodash/uniq';
import { GridCellModes, GridCellModesModel, GridColDef } from '@mui/x-data-grid-premium';
import { isRevContent, isTaboola } from '@/utils/network-utils.ts';
import { commonColumns } from '../../../../utils/commonColumns';
import ReportTable from '../../../DataTable/ReportTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks.ts';
import { openSnackbar, updateMainStore } from '../../../../Redux/Slices/main.ts';
import { CurrencyComparator, editTableRows } from '../../../../utils/tables-utils.tsx';
import networkRequest from '../../../../utils/networkRequest.ts';
import { budgetCPCTaboolaValidation, budgetValidation } from '../../AddCampaign/Components/Validation/Details.tsx';
import DialogComponent from '../../../../utils/UIElements/Dialog.tsx';
import { checkConfirmBudget } from '../../CampaignDetails/Settings/CampaignSettings/useSubmitSettings.tsx';
import { CampaignNameCell, EditBudget, StatusToggle } from './helperComponents.tsx';
import { aggregationModel } from './helperConstants.ts';

interface RowType {
	budget?: string;
	cpc?: string;
	bid_strategy?: string;
	campaign_id?: string;
	network_id?: string;
	id?: string | number;
}

const Report = () => {
	const { tableRows, userID } = useAppSelector(state => state.main);
	const { network } = useAppSelector(state => state.filters);
	const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
	const [submittingBudgets, setSubmittingBudgets] = useState<Array<any>>([]);
	const [warningStatusMessage, setWarningStatusMessage] = useState<string>('');
	const [budgetChangeConfirmMessage, setBudgetChangeConfirmMessage] = useState<string>('');
	const [warningBudgetMessage, setWarningBudgetMessage] = useState<string[]>([]);
	const [modifiedRow, setModifiedRow] = useState<RowType>({});
	const [updatingStatus, setUpdatingStatus] = useState(false);
	const [updatingFnfStatus, setUpdatingFnfStatus] = useState(false);
	const dispatch = useAppDispatch();

	const changeStatus = async (
		id: number,
		newStatus: boolean,
		row: {
			campaign_id: string;
			network_campaign_id: string;
			network_id: number;
			usm_id: number;
		}
	) => {
		setUpdatingStatus(true);
		dispatch(
			updateMainStore({
				key: 'tableRows',
				value: editTableRows(tableRows, id, 'status', newStatus),
			})
		);
		networkRequest(
			'api/v1/campaign/status/update',
			{
				campaignID: row.campaign_id,
				networkCampaignID: row.network_campaign_id,
				status: newStatus,
				userID,
				network: row.network_id,
				usmID: row.usm_id,
			},
			'POST'
		)
			.then(response => response.json())
			.then(response => {
				if (response && typeof response === 'object') {
					if (!response.success) {
						dispatch(
							updateMainStore({
								key: 'tableRows',
								value: editTableRows(tableRows, id, 'status', !newStatus),
							})
						);
						dispatch(
							openSnackbar({
								children: response.message || 'Something went wrong. Please try again',
								severity: 'error',
							})
						);
					} else if (response.warnings?.length) {
						setWarningStatusMessage(response.warnings[0]);
						setModifiedRow(row);
					}
				}
			})
			.catch(() => {
				dispatch(
					updateMainStore({
						key: 'tableRows',
						value: editTableRows(tableRows, id, 'status', !newStatus),
					})
				);
			})
			.finally(() => setUpdatingStatus(false));
	};

	const changeFireAndForgetStatus = async (id: number, newFireAndForgetStatus: boolean, row: RowType) => {
		setUpdatingFnfStatus(true);
		dispatch(
			updateMainStore({
				key: 'tableRows',
				value: editTableRows(tableRows, id, 'fireAndForgetStatus', newFireAndForgetStatus),
			})
		);
		return networkRequest(
			'api/v1/campaign/fnf/status/update',
			{
				campaignID: row.campaign_id,
				network: row.network_id,
				fireAndForgetStatus: newFireAndForgetStatus,
			},
			'POST'
		)
			.then(response => response.json())
			.then(response => {
				if (response && typeof response === 'object') {
					if (!response.success) {
						dispatch(
							updateMainStore({
								key: 'tableRows',
								value: editTableRows(tableRows, id, 'fireAndForgetStatus', !newFireAndForgetStatus),
							})
						);
						dispatch(
							openSnackbar({
								children: response.message || 'Something went wrong. Please try again',
								severity: 'error',
							})
						);
					}
					setModifiedRow({});
				}
			})
			.catch(() => {
				dispatch(
					updateMainStore({
						key: 'tableRows',
						value: editTableRows(tableRows, id, 'fireAndForgetStatus', !newFireAndForgetStatus),
					})
				);
			})
			.finally(() => setUpdatingFnfStatus(false));
	};

	const updateBudget = async (updatedRow: RowType, originalRow: RowType) => {
		return new Promise((resolve, reject) => {
			const validationMessage = budgetValidation(updatedRow.budget, updatedRow.network_id);
			if (validationMessage) {
				dispatch(openSnackbar({ children: validationMessage, severity: 'error' }));
				reject(originalRow);
			} else if (
				isTaboola(updatedRow.network_id) &&
				budgetCPCTaboolaValidation(updatedRow.budget, updatedRow.cpc, updatedRow.bid_strategy)
			) {
				setBudgetChangeConfirmMessage(
					checkConfirmBudget({
						...updatedRow,
						bidStrategy: updatedRow.bid_strategy,
						network: updatedRow.network_id,
					})
				);
				setModifiedRow(originalRow);
				setCellModesModel({
					...cellModesModel,
					[modifiedRow.id!]: {
						budget: { mode: GridCellModes.View, ignoreModifications: true },
					},
				});
				reject(originalRow);
			} else {
				setSubmittingBudgets(uniq([...submittingBudgets, updatedRow.id]));
				networkRequest('api/v1/campaign/settings/budget/update', {
					campaignID: updatedRow.campaign_id,
					network: updatedRow.network_id,
					budget: updatedRow.budget,
				})
					.then(response => response.json())
					.then(response => {
						setSubmittingBudgets(submittingBudgets.filter((id: string) => id !== updatedRow.id));
						if (response && !response.success) {
							dispatch(
								openSnackbar({
									children: response.message || 'Something went wrong. Please try again.',
									severity: 'error',
								})
							);
							dispatch(
								updateMainStore({
									key: 'tableRows',
									value: editTableRows(tableRows, Number(updatedRow.id), 'budget', String(originalRow.budget)),
								})
							);
							resolve(originalRow);
						} else {
							dispatch(
								updateMainStore({
									key: 'tableRows',
									value: editTableRows(tableRows, Number(updatedRow.id), 'budget', String(updatedRow.budget)),
								})
							);
							dispatch(openSnackbar({ children: 'Budget was updated successfully.', severity: 'success' }));
							if (response.warnings?.length) {
								setWarningBudgetMessage(response.warnings);
							}
							resolve(updatedRow);
						}
					});
			}
		});
	};

	const columnsList = (networkFilter: string) => {
		const columns = [
			{
				field: 'status',
				headerName: 'Status',
				align: 'center',
				headerAlign: 'center',
				width: 78,
				renderCell: params => StatusToggle(params, 'campaign-status', changeStatus, updatingStatus),
			},
			{
				field: 'fireAndForgetStatus',
				headerName: 'F&F',
				align: 'center',
				headerAlign: 'center',
				width: 78,
				renderCell: params =>
					StatusToggle(params, 'fire-and-forget-status', changeFireAndForgetStatus, updatingFnfStatus),
			},
			{
				field: 'campaign_name',
				headerName: 'Campaign Name',
				width: 300,
				renderCell: CampaignNameCell,
			},
			{
				field: 'budget',
				headerName: 'Budget',
				headerClassName: 'pr-11',
				width: 150,
				type: 'number',
				sortComparator: CurrencyComparator,
				renderCell: params => (
					<EditBudget
						row={params.row}
						onChange={async budget => {
							await updateBudget({ ...params.row, budget }, params.row);
						}}
					/>
				),
			},
			...commonColumns([
				'clicks',
				'conversions',
				'average_cpc',
				'cpc',
				'epc',
				'cpa',
				'spend',
				'revenue',
				'profit',
				'profit_percent',
			]),
			{ field: 'network_campaign_id', headerName: 'Campaign ID', width: 150 },
			{
				field: 'created_at',
				headerName: 'Created At',
				width: 150,
				type: 'date',
				valueGetter: (_value, row) => (row.created_at !== undefined ? new Date(row.created_at) : ''),
			},
		] satisfies GridColDef[];
		if (!isRevContent(networkFilter)) {
			columns.splice(-2, 0, { field: 'bidStrategy', headerName: 'Bid Strategy', width: 160 });
		}
		return columns;
	};

	const confirmBudgetChange = async () => {
		const newBudget = Number(modifiedRow.cpc) * 30;
		setSubmittingBudgets(uniq([...submittingBudgets, modifiedRow.id]));
		return await networkRequest('api/v1/campaign/settings/budget/update', {
			campaignID: modifiedRow.campaign_id,
			network: modifiedRow.network_id,
			budget: newBudget,
		})
			.then(response => response.json())
			.then(response => {
				setSubmittingBudgets(submittingBudgets.filter((id: string) => id !== modifiedRow.id));
				if (response && !response.success) {
					dispatch(
						openSnackbar({
							children: response.message || 'Something went wrong. Please try again.',
							severity: 'error',
						})
					);
					dispatch(
						updateMainStore({
							key: 'tableRows',
							value: editTableRows(tableRows, Number(modifiedRow.id), 'budget', String(modifiedRow.budget)),
						})
					);
				} else {
					dispatch(
						updateMainStore({
							key: 'tableRows',
							value: editTableRows(tableRows, Number(modifiedRow.id), 'budget', String(newBudget)),
						})
					);
					dispatch(openSnackbar({ children: 'Budget was updated successfully.', severity: 'success' }));
					if (response.warnings?.length) {
						setWarningBudgetMessage(response.warnings);
					}
				}
				setModifiedRow({});
			});
	};

	const dialogComponents = (
		<>
			<DialogComponent
				dialogOpen={!!warningStatusMessage}
				title="Confirm"
				useConfirm={async () => {
					await changeFireAndForgetStatus(Number(modifiedRow.id), false, modifiedRow);
					setWarningStatusMessage('');
				}}
				useCancel={() => setWarningStatusMessage('')}
			>
				<p>{warningStatusMessage}</p>
			</DialogComponent>
			<DialogComponent
				dialogOpen={!!budgetChangeConfirmMessage}
				title="Confirm"
				useConfirm={async () => {
					await confirmBudgetChange();
					setBudgetChangeConfirmMessage('');
				}}
				useCancel={() => {
					setBudgetChangeConfirmMessage('');
					setModifiedRow({});
				}}
			>
				<p>{budgetChangeConfirmMessage}</p>
			</DialogComponent>
			<DialogComponent
				title="Warning"
				dialogOpen={!!warningBudgetMessage.length}
				useCancel={() => setWarningBudgetMessage([])}
			>
				<>
					{warningBudgetMessage.map(msg => (
						<p>{msg}</p>
					))}
				</>
			</DialogComponent>
		</>
	);

	return (
		<div className="manage-report">
			<ReportTable
				endpoint="api/v1/manage/table"
				columns={columnsList(network)}
				tableName="Manage Campaigns"
				notCampaignDetails
				filters={['user', 'status', 'archiveStatus', 'network', 'isAgency']}
				sortModel={[{ field: 'spend', sort: 'desc' }]}
				defaultPinnedColumns={{ left: ['campaign_name'] }}
				aggregationModel={aggregationModel}
				cellModesModel={cellModesModel}
				onCellModesModelChange={(model: GridCellModesModel) => setCellModesModel(model)}
				processRowUpdate={updateBudget}
			/>
			{dialogComponents}
		</div>
	);
};

export default Report;
