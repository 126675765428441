import { FormEvent, ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { deactivateLinkTest } from '../Settings/StartLinkTest/useSubmitStartLinkTest.tsx';

export default function NotificationStripe() {
	const dispatch = useAppDispatch();
	const { campaignNotifications, linkTestNotification, campaignID, network } = useAppSelector((state) => state.campaignDetails);

	const deactivateLinkTestCallback = async (e: FormEvent) => {
		e.preventDefault();
		await deactivateLinkTest(linkTestNotification, campaignID, network, dispatch);
	};

	const notificationsList: (string | ReactElement)[] = [...campaignNotifications];
	if (linkTestNotification) {
		const linkTestMessage =
			<>
				{linkTestNotification}
				<span>
					<a href=" " onClick={deactivateLinkTestCallback} style={{ color: 'white', border: 'solid white 2px', padding: 4, borderRadius: 8, cursor: 'pointer' }}>Cancel Test</a>
				</span>
			</>;
		notificationsList.push(linkTestMessage);
	}

	const listItemClass = (index: number) => {
		let className = '';
		if (notificationsList && notificationsList.length > 1) {
			className =  'campaign-details-notification-multiple-list-items';
		}
		if (index === notificationsList.length - 1) {
			className += ' campaign-details-notification-last-list-item';
		}
		return className;
	}

	return notificationsList && notificationsList.length > 0 ? (
		<div className="campaign-details-notification-stripe">
			<ul>
				{notificationsList.map((notification, index) => (
					<li key={`${index}_${notification}`} className={listItemClass(index)}>{notification}</li>
				))}
			</ul>
		</div>
	) : <div/>;
}
