import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import NoImage from '@/Icons/NoImage';
import { changeNonField } from '@/Redux/Slices/addCampaign.ts';
import networkRequest from '@/utils/networkRequest';
import { Pagination, TablePagination } from '@mui/material';
import { useEffect, useState } from 'react';
import Ad from './Ad';

export default function DisplayAdsSection({ isCreatives = false }) {
	const dispatch = useAppDispatch();
	const ads = useAppSelector(state => state.addCampaign.ads);
	const [groupOptions, setGroupOptions] = useState<{ value: string; label: string }[]>([]);

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 24;
	const totalPages = Math.ceil(ads.length / itemsPerPage);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const paginatedAds = ads.slice(startIndex, startIndex + itemsPerPage);

	useEffect(() => {
		networkRequest('api/v1/user/group/pull', {})
			.then(response => response.json())
			.then(data => {
				if (data?.length > 0) {
					setGroupOptions([
						{ value: '0', label: 'Please Select' },
						...data.map((group: { id: any; name: any }) => ({ value: group.id, label: group.name })),
					]);
				}
			});
		dispatch(changeNonField({ key: 'ads', value: ads.map(ad => ({ ...ad, uuid: ad.uuid || crypto.randomUUID() })) }));
	}, []);

	return (
		<div className="relative flex flex-col">
			<div className="mb-2 mt-6 border-b font-semibold text-black/60">{isCreatives ? 'Creatives' : 'Ads'}</div>

			{ads.length === 0 ? (
				<div className="flex min-h-[50vh] w-full flex-col items-center justify-center gap-3">
					<NoImage className="h-20 w-20 fill-black/20" />
					<div className="text-xl font-semibold text-black/20">No Ads To Display</div>
				</div>
			) : (
				<div className="grid w-full gap-6 p-3 pb-6 auto-fill-80">
					{paginatedAds.map((ad, index) => (
						<Ad key={index} ad={ad} index={index} isCreative={isCreatives} groupOptions={groupOptions} />
					))}
				</div>
			)}

			{totalPages > 1 && (
				<TablePagination
					className="absolute right-0 top-2 m-0 border-none p-0"
					count={ads.length}
					page={currentPage - 1}
					onPageChange={(_, page) => setCurrentPage(page + 1)}
					rowsPerPage={itemsPerPage}
					rowsPerPageOptions={[]}
				/>
			)}

			{totalPages > 1 && (
				<Pagination
					className="ml-auto"
					count={totalPages}
					page={currentPage}
					onChange={(_, page) => setCurrentPage(page)}
					variant="outlined"
					shape="circular"
					size="small"
					showFirstButton
					showLastButton
				/>
			)}
		</div>
	);
}
