import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import intersection from 'lodash/intersection';
import SectionTitle from '../../Components/SectionTitle.tsx';
import Input from '../../Fields/Input.tsx';
import Select from '../../Fields/Select.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { AddCampaignState, changeFireAndForgetField, changeSettingsField } from '@/Redux/Slices/addCampaign.ts';
import { Network } from '@/utils/network-utils.ts';
import ChipSelect from '../../Fields/ChipSelect.tsx';
import networkRequest from '../../../../../utils/networkRequest.ts';
import {
	brandingTextField,
	conversionCapFields,
	sectionTitleNetworkDetails,
	budgetField,
	campaignNameField,
	siteRuleSetField,
	startDateField,
	splitByDeviceToggle,
	countryTargetingField,
	cpcField, ottoSection,
} from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/fields.tsx';
import { pullGroupOptions, handleChipSelect } from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/functions.ts';
import {
	defaultAccountOptions, defaultGroupOptions, defaultSubAccountOptions, platformOptions,
} from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/consts.ts';

const trafficAllocationOptions: Array<{ value: string, label: string }> = [
	{ value: '1', label: 'Even' },
	{ value: '2', label: 'Optimized' },
];

const osTargetingOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'All'},
	{value: '1', label: 'Mac OS X'},
	{value: '2', label: 'iOS'},
	{value: '3', label: 'Android'},
	{value: '4', label: 'Linux'},
	{value: '5', label: 'Windows'},
];

const browserOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'All'},
	{value: '1', label: 'Chrome'},
	{value: '2', label: 'Safari'},
	{value: '3', label: 'Facebook In-App Browser'},
	{value: '4', label: 'Edge'},
	{value: '5', label: 'WebView'},
	{value: '6', label: 'Chromium'},
	{value: '7', label: 'Firefox'},
	{value: '8', label: 'Samsung Browser'},
	{value: '9', label: 'Internet Explorer'},
	{value: '10', label: 'Google Search App'},
	{value: '11', label: 'Android App'},
	{value: '12', label: 'Opera'},
	{value: '13', label: 'MIUI Browser'},
	{value: '14', label: 'Amazon Silk'},
	{value: '15', label: 'UC Browser'},
	{value: '16', label: 'Yandex'},
];

const connectionOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'All'},
	{value: '1', label: 'Wi-Fi Only'},
];

const marketingObjective: Array<{ value: string, label: string }> = [
	{value: '1', label: 'Brand Awareness'},
	{value: '2', label: 'Online Purchases'},
	{value: '3', label: 'Lead Generation'},
	{value: '4', label: 'Drive Website Traffic'},
];

const bidStrategyOptions: Array<{ value: string, label: string }> = [
	{value: '1', label: 'Fixed'},
	{value: '2', label: 'Smart'},
	{value: '3', label: 'Max Conversions'},
	{value: '4', label: 'Target CPA'},
];

const modifyOSTargetingOptions = (selectedPlatforms: string[]) => {
	if (selectedPlatforms.includes('0') || ['1', '2', '3'].every((val: string) => selectedPlatforms.includes(val))) {
		return osTargetingOptions;
	}
	if ((selectedPlatforms.includes('2') || selectedPlatforms.includes('3')) && !selectedPlatforms.includes('1')) {
		return [
			osTargetingOptions[0],
			osTargetingOptions[2],
			osTargetingOptions[3],
		];
	}
	if (selectedPlatforms.includes('1') && !(selectedPlatforms.includes('2') || selectedPlatforms.includes('3'))) {
		return [
			osTargetingOptions[0],
			osTargetingOptions[1],
			osTargetingOptions[4],
			osTargetingOptions[5],
		];
	}
	return osTargetingOptions;
};

export const modifyOSTargetingValue = (newPlatformValue: string[], osTargetingValue: string[]) => {
	const newOSOptionsKeys = modifyOSTargetingOptions(newPlatformValue).map(val => val.value);
	const availableOptions = intersection(newOSOptionsKeys, osTargetingValue);
	if (!availableOptions.length) {
		return ['0'];
	}
	return availableOptions;
};

export default function TaboolaDetails({campaignDetails = false}: { campaignDetails?: boolean }) {
	const [groupOptionsLoading, setGroupOptionsLoading] = useState(false);
	const [groupOptions, setGroupOptions] = useState(defaultGroupOptions);
	const [accountOptionsLoading, setAccountOptionsLoading] = useState(false);
	const [accountOptions, setAccountOptions] = useState(defaultAccountOptions);
	const [subAccountOptionsLoading, setSubAccountOptionsLoading] = useState(false);
	const [subAccountOptions, setSubAccountOptions] = useState(defaultSubAccountOptions);
	const [accountData, setAccountData] = useState<any[]>([]);
	const [previousCPC, setPreviousCPC] = useState(0);
	const [previousSiteAcceptableProfit, setPreviousSiteAcceptableProfit] = useState(true);

	const dispatch = useAppDispatch();
	const {settings, errors, isClone} = useAppSelector((state) => state.addCampaign);
	const updateSettings = <K extends keyof AddCampaignState['settings']>(
		name: K,
		value: AddCampaignState['settings'][K]
	) => {
		dispatch(changeSettingsField({ name, value }))
	}
	const updateFireAndForget = <K extends keyof AddCampaignState['fireAndForget']>(
		name: K,
		value: AddCampaignState['fireAndForget'][K]
	) => {
		dispatch(changeFireAndForgetField({ name, value }))
	}

	useEffect(() => {
		if (!campaignDetails) {
			pullGroupOptions(setGroupOptionsLoading, setGroupOptions);
		}
		if (isClone) {
			pullAccountOptions(settings.group);
			pullSubAccountOptions(settings.usmID);
		}
	}, []);

	const pullAccountOptions = (group) => {
		if (group === '0') {
			setAccountOptions(defaultAccountOptions);
			return;
		}
		setAccountOptionsLoading(true);
		networkRequest("api/v1/accounts/pull", {group, network: Network.Taboola}, "POST")
			.then((response) => response.json())
			.then((data) => {
				if (data && typeof data === 'object' && data.length > 0) {
					setAccountData(data);
					setAccountOptions(
						[{value: '0', label: 'Please Select'}].concat(data.map((account) => ({
							value: account.id,
							label: account.name
						})))
					);
				}
			})
			.then(() => setAccountOptionsLoading(false))
			.catch(() => setAccountOptionsLoading(false));
	}

	const pullSubAccountOptions = (usmID) => {
		if (usmID) {
			setSubAccountOptionsLoading(true);
			networkRequest("api/v1/accounts/sub/pull", {usm_id: usmID, network: Network.Taboola}, "POST")
				.then((response) => response.json())
				.then((data) => {
					if (data && typeof data === 'object' && data.length > 0) {
						setSubAccountOptions(
							[{value: '0', label: 'Please Select'}].concat(data.map((subAccount) => ({
								value: subAccount.id,
								label: subAccount.name
							})))
						);
					}
				})
				.then(() => setSubAccountOptionsLoading(false))
				.catch(() => setSubAccountOptionsLoading(false));
		} else {
			setSubAccountOptions(defaultSubAccountOptions);
		}
	}

	const elementProps = { settings, errors, updateSettings };

	return (
		<>
			{sectionTitleNetworkDetails('Taboola Campaign Details', settings, updateSettings)}
			<div className="add-campaign-field-row">
				{campaignNameField(elementProps)}
				{!campaignDetails && (
					<>
						{siteRuleSetField(elementProps)}
						{startDateField(elementProps)}
					</>
				)}
			</div>
			<div className="add-campaign-field-row">
				{budgetField(elementProps)}
				{brandingTextField(elementProps)}
				<Select
					onChange={({target: {value}}) => updateSettings('creativeTrafficAllocation', value)}
					label="Creative Traffic Allocation"
					value={settings.creativeTrafficAllocation}
					name="creative-traffic-allocation"
					options={trafficAllocationOptions}
					error={!!errors.fields.creativeTrafficAllocation}
					helperText={errors.fields.creativeTrafficAllocation}
				/>
			</div>
			<div className="add-campaign-field-row">
				{conversionCapFields(elementProps)}
			</div>
			<div className="mb-3"/>
			{!campaignDetails &&
        <>
          <SectionTitle title="Account Details"/>
          <div className="add-campaign-field-row">
            <Select
              onChange={({target: {value}}) => {
								updateSettings('group', value);
								updateSettings('accountId', '0');
								pullAccountOptions(value);
							}}
              label="Group"
              value={settings.group}
              name="campaign-group"
              options={groupOptions}
							disabled={groupOptionsLoading}
              error={!!errors.fields.group}
              helperText={errors.fields.group}
            />
						{settings.group !== '0' && accountOptions.length > 1 ? (
							<Select
								onChange={({target: {value}}) => {
									const usmID = accountData.filter((account: { id: string, usm_id: string }) => account.id === value)[0]?.usm_id || '';
									updateSettings('accountId', value);
									updateSettings('usmID', usmID);
									updateSettings('subAccountId', '0')
									pullSubAccountOptions(usmID);
								}}
								label="Taboola Account"
								value={settings.accountId}
								name="taboola-account"
								options={accountOptions}
								error={!!errors.fields.accountId}
								helperText={errors.fields.accountId}
							/>
						)
							: accountOptionsLoading && <div className="progress-inline-container"><CircularProgress /></div>
						}
						{settings.accountId !== '0' && subAccountOptions.length > 1 ? (
							<Select
								onChange={({target: {value}}) => updateSettings('subAccountId', value)}
								label="Taboola Sub Account"
								value={settings.subAccountId}
								name="taboola-sub-account"
								options={subAccountOptions}
								error={!!errors.fields.subAccountId}
								helperText={errors.fields.subAccountId}
							/>
						)
							: subAccountOptionsLoading && <div className="progress-inline-container"><CircularProgress /></div>
						}
          </div>
          <div className="mb-3"/>
        </>}
			<SectionTitle title="Campaign Targeting"/>
			<div className="add-campaign-field-row">
				<ChipSelect
					onChange={({ target: { value } }) => {
						const newValue = handleChipSelect(value, settings.platformTargeting);
						updateSettings('platformTargeting', newValue);
						const modifiedOSTargetingValue = modifyOSTargetingValue(newValue, settings.osTargeting);
						updateSettings('osTargeting', modifiedOSTargetingValue);
					}}
					label="Platform Targeting"
					value={settings.platformTargeting}
					name="platform-targeting"
					options={platformOptions}
					error={!!errors.fields.platformTargeting}
					helperText={errors.fields.platformTargeting}
				/>
				<ChipSelect
					onChange={({ target: { value} }) => updateSettings('osTargeting', handleChipSelect(value, settings.osTargeting))}
					label="OS Targeting"
					value={settings.osTargeting}
					name="os-targeting"
					options={modifyOSTargetingOptions(settings.platformTargeting)}
					error={!!errors.fields.osTargeting}
					helperText={errors.fields.osTargeting}
				/>
				<Select
					onChange={({ target: { value } }) => updateSettings('connectionType', value)}
					label="Connection Type"
					value={settings.connectionType}
					name="connection-type"
					options={connectionOptions}
					error={!!errors.fields.connectionType}
					helperText={errors.fields.connectionType}
				/>
			</div>
			{!campaignDetails && settings.platformTargeting.includes('0') &&
				splitByDeviceToggle(elementProps)}
			<div className="add-campaign-field-row">
				<Select
					onChange={({target: {value}}) => updateSettings('marketingObjective', value)}
					label="Marketing Objective"
					value={settings.marketingObjective}
					name="marketing-objective"
					options={marketingObjective}
					error={!!errors.fields.marketingObjective}
					helperText={errors.fields.marketingObjective}
				/>
				<Select
					onChange={({target: {value}}) => updateSettings('browserTargeting', [value])}
					label="Browser Targeting"
					value={settings.browserTargeting[0]}
					name="browser-targeting"
					options={browserOptions}
					error={!!errors.fields.browserTargeting}
					helperText={errors.fields.browserTargeting}
				/>
				{countryTargetingField(elementProps)}
			</div>
			<div className="mb-3" />
			<SectionTitle title="Taboola Optimization" />
			<div className="add-campaign-field-row">
				<Select
					onChange={({target: {value}}) => {
						if (value === '3' || value === '4') {
							if (settings.cpc !== '0') {
								setPreviousCPC(settings.cpc);
							}
							updateSettings('cpc', '0');
						} else {
							updateSettings('targetCPA', '0');
							if (previousCPC && +previousCPC !== 0) {
								updateSettings('cpc', previousCPC);
								setPreviousCPC(0);
							}
						}
						if (value === '2') {
							setPreviousSiteAcceptableProfit(settings.siteAcceptableProfitActive);
							updateFireAndForget('campaignAcceptableProfitActive', false);
						} else {
							updateFireAndForget('campaignAcceptableProfitActive', previousSiteAcceptableProfit || false);
						}
						updateSettings('bidStrategy', value);
					}}
					label="Bid Strategy"
					value={settings.bidStrategy}
					name="bid-strategy"
					options={campaignDetails ? bidStrategyOptions : bidStrategyOptions.slice(0, -1)}
					error={!!errors.fields.bidStrategy}
					helperText={errors.fields.bidStrategy}
				/>
				{(!(['3', '4'].includes(settings.bidStrategy)) && !settings.maximusDayparting) &&
					cpcField(elementProps)}
				{settings.bidStrategy === '4' && (
					<Input
						onChange={({target: {value}}) => updateSettings('targetCPA', value)}
						label="Target CPA"
						value={settings.targetCPA}
						numeric
						name="campaign-targetCPA"
						startAdornment={<span>$</span>}
						error={!!errors.fields.targetCPA}
						helperText={errors.fields.targetCPA}
					/>
				)}
			</div>
			{ottoSection(elementProps)}
		</>
	)
}
