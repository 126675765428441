import { PREDEFINED_RANGES } from '@/Components/Filters/DateRange.tsx'
import { useAppDispatch } from '@/hooks'
import { changeDate, refreshAll } from '@/Redux/Slices/filters'
import { useEffect, useRef } from 'react'
import { getFromLocalStorage } from './local-storage-utils'

const parseStoredFilters = () => {
	const currentRangeDataStr = getFromLocalStorage(getFromLocalStorage('userID') || 'dateRange')
	const currentRangeData = JSON.parse(currentRangeDataStr || '{}')

	if (
		currentRangeData.dateRange === 'custom' &&
		currentRangeData.startDate &&
		currentRangeData.endDate
	) {
		return currentRangeData
	}

	const currentDateRange = currentRangeData.dateRange || 'today'
	const presetRange = PREDEFINED_RANGES[currentDateRange] || PREDEFINED_RANGES.today
	const presetData = presetRange.range()

	return {
		dateRange: presetData.range,
		startDate: presetData.startDate.toJSON(),
		endDate: presetData.endDate.toJSON(),
	}
}

export default function useInitializeApp() {
	const dispatch = useAppDispatch()
	const dateRef = useRef(new Date().getDate())

	useEffect(() => {
		const storedFilters = parseStoredFilters()
		localStorage.setItem(
			localStorage.getItem('userID') || 'dateRange',
			JSON.stringify(storedFilters)
		)
		dispatch(changeDate(storedFilters))

		const verifyDateFilters = (dayOfTheMonth: number) => {
			if (dayOfTheMonth !== new Date().getDate()) {
				const dateFilters = parseStoredFilters()
				localStorage.setItem(
					localStorage.getItem('userID') || 'dateRange',
					JSON.stringify(dateFilters)
				)
				dateRef.current = new Date().getDate()
				dispatch(changeDate(dateFilters))
				dispatch(refreshAll(true))
			}
		}

		verifyDateFilters(dateRef.current)
		const checkDateInterval = setInterval(() => verifyDateFilters(dateRef.current), 60_000)
		return () => {
			clearInterval(checkDateInterval)
		}
	}, [])
}
