import Input from '@/Components/Pages/AddCampaign/Fields/Input';
import { useAppDispatch } from '@/hooks';
import Flag from '@/Icons/Flag';
import { store } from '@/Redux/reduxStore';
import { openSnackbar } from '@/Redux/Slices/main';
import { Button, CircularProgress, Dialog, IconButton, Tooltip } from '@mui/material';
import { ComponentProps, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { networkRequestMultipart } from '../networkRequest';

const subjectMap = {
	'/': 'Manage Campaigns',
	'/add_campaign_outbrain': 'Add Campaign - Outbrain',
	'/add_campaign_revcontent': 'Add Campaign - Revcontent',
	'/add_campaign_taboola': 'Add Campaign - Taboola',
	'/campaign_details': 'Campaign Details',
	'/clone_campaign_outbrain': 'Clone Campaign - Outbrain',
	'/clone_campaign_revcontent': 'Clone Campaign - Revcontent',
	'/clone_campaign_taboola': 'Clone Campaign - Taboola',
	'/creatives_gallery': 'Creatives Gallery',
	'/help': 'Help Page',
};

type IssueReportProps = {
	color: ComponentProps<typeof IconButton>['color'];
	label?: string;
	className?: string;
	iconClassName?: string;
};

export default function IssueReport({ color, label, className, iconClassName }: IssueReportProps) {
	const dispatch = useAppDispatch();
	const [screenCapture, setScreenCapture] = useState<File>();
	const [open, setOpen] = useState(false);
	const [description, setDescription] = useState('');
	const [loading, setLoading] = useState(false);

	const firstFragment = `/${window.location.pathname.split('/')[1]}`;
	label ||= subjectMap[firstFragment] || 'Unmapped Subject'; // Whenever we get a report from an unmapped page, we'll know to add it to the map

	async function handleButtonClick() {
		setDescription('');
		const screenshot = await captureScreen();
		setScreenCapture(screenshot);
		setOpen(true);
	}

	async function handleSubmit() {
		setLoading(true);
		const state = store.getState();
		await networkRequestMultipart('api/v1/issues/report', {
			description,
			attachments: screenCapture,
			subject: `Maximus - Report a Problem - ${label}`,
			email: state.main.email,
			created_at: new Date().toISOString(),
			local_timestamp: new Date().toLocaleString(),
			user_agent: navigator.userAgent,
			maximus_user_id: state.main.userID,
			current_url: window.location.href,
			campaign_name: state.campaignDetails.campaignName || 'none',
			redux_state_snapshot: JSON.stringify(state),
		})
			.then(response => response.json())
			.then(data => {
				if (data?.success) {
					dispatch(openSnackbar({ children: 'Support ticket submitted successfully!', severity: 'success' }));
				} else {
					console.error('Error:', data);
					dispatch(
						openSnackbar({
							children: 'There was an error submitting your support ticket. Please try again.',
							severity: 'error',
						})
					);
				}
			})
			.finally(() => {
				setLoading(false);
				setOpen(false);
			});
	}

	return (
		<>
			<Tooltip title={`Click to report a new issue for ${label}`}>
				<IconButton color={color} size="small" onClick={handleButtonClick} className={className}>
					<Flag className={twMerge('size-6', iconClassName)} />
				</IconButton>
			</Tooltip>

			<Dialog open={open} maxWidth={false}>
				<h1 className="border-b p-3 text-center text-3xl font-semibold">Open Support Ticket</h1>

				<section className="flex w-[800px] flex-col gap-2 overflow-y-auto px-6 py-6">
					<p>Describe your issue with {label}:</p>
					<Input
						multiline
						label="Issue description"
						name="issue-description"
						size={{ mb: 3, width: '100%' }}
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>

					<p>The following screen capture will be sent with your report:</p>
					{!!screenCapture && <img className="rounded shadow-md" src={URL.createObjectURL(screenCapture)} />}
				</section>

				<footer className="flex gap-6 border-t p-6">
					<Button className="ml-auto" variant="text" disabled={loading} onClick={() => setOpen(false)}>
						Cancel
					</Button>

					<Button
						variant="contained"
						disabled={loading || !description}
						startIcon={loading ? <CircularProgress size={16} color="inherit" /> : undefined}
						onClick={handleSubmit}
					>
						Submit Report
					</Button>
				</footer>
			</Dialog>
		</>
	);
}

async function captureScreen(): Promise<File> {
	try {
		const stream = await navigator.mediaDevices.getDisplayMedia({
			preferCurrentTab: true, // TODO: Experimental API, should currently work in all modern browsers but non-standard, let's check back on this later
			video: true,
		});

		const video = document.createElement('video');
		video.srcObject = stream;

		return new Promise((resolve, reject) => {
			video.onloadedmetadata = () => {
				video.play();
				const canvas = document.createElement('canvas');
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				canvas.getContext('2d')?.drawImage(video, 0, 0);
				stream.getTracks().forEach(track => track.stop());
				canvas.toBlob(blob => {
					if (!blob) {
						reject(new Error('Failed to create blob from canvas'));
						return;
					}
					const file = new File([blob], `screenshot-${new Date().toISOString()}.png`, {
						type: 'image/png',
						lastModified: Date.now(),
					});
					resolve(file);
				}, 'image/png');
			};
			video.onerror = reject;
		});
	} catch (err) {
		console.error('Screenshot failed:', err);
		throw `Screenshot failed: ${err}`;
	}
}
