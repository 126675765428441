import {IconButton, Tooltip} from '@mui/material';
import ReportTable from '../../../../DataTable/ReportTable';
import { commonColumns } from '../../../../../utils/commonColumns';
import Toggle from '../../../../FormComponents/Toggle.tsx';
import { editTableRows } from '../../../../../utils/tables-utils.tsx';
import { openSnackbar, updateMainStore } from '../../../../../Redux/Slices/main';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { changeCampaignDetails } from '../../../../../Redux/Slices/campaignDetails.ts';
import Edit from '../../../../../Icons/Edit.tsx';
import Warning from "../../../../../Icons/Warning";
import { GridColDef } from '@mui/x-data-grid-premium';
import { ClipboardCell } from './shared/ClipboardCell.tsx';
import { ImageCell } from './shared/ImageCell.tsx';
import { conditionalEditAdColumn, conditionalPresellCTR, visitsAndClicks } from '@/utils/conditionalColumns.tsx';
import { useMemo } from 'react';

const StatusIcon = ({status = 'Rejected', warning = false}) => (
	<Tooltip title={status || ''} placement="top">
		<div className="ad-name-status">
			<Warning className={warning ? 'pending' : 'rejected'} />
		</div>
	</Tooltip>
);

export default function Images({ all = false }) {
	const { tableRows } = useAppSelector((state) => state.main);
	const { campaignID, network, advancedTracking, maximusLinks, estimatedSpends } = useAppSelector((state) => state.campaignDetails);
	const dispatch = useAppDispatch();

	const changeStatus = async (id: number, newStatus: boolean, adID: number) => {
		dispatch(updateMainStore({ key: 'tableRows', value: editTableRows(tableRows, id, 'status', newStatus)}));
		networkRequest('api/v1/campaign/ad/status/update', { campaignID, network, status: newStatus, adID: (adID).toString() })
			.then((response) => response.json())
			.then((response) => {
					if (response && !response.success) {
						dispatch(updateMainStore({ key: 'tableRows', value: editTableRows(tableRows, id, 'status', !newStatus)}));
						dispatch(openSnackbar(
							{
								children: response.message || 'Something went wrong. Please try again.',
								severity: 'error',
							},
						));
					}
				}
			);
	}

	const NONDisabledStatuses = ['RUNNING', 'PAUSED', 'APPROVED'];
	const WarningStatus = ['PENDING APPROVAL', 'PENDING'];

	const statusToggle = (params: object) => (
		<>
			<Toggle
				name="image-status"
				onChange={() => changeStatus(params.row.id, !params.value, params.row.adId)}
				value={params.value}
				disabled={params.row.textStatus && ![...WarningStatus, ...NONDisabledStatuses].includes(params.row.textStatus?.toUpperCase()) }
			/>
			{(params.row.textStatus && !NONDisabledStatuses.includes(params.row.textStatus?.toUpperCase())) &&
        <StatusIcon status={params?.row?.textStatus || ''} warning={WarningStatus.includes(params.row.textStatus?.toUpperCase())} />}
		</>
	);

	const editAdData = (params: object) => {
		dispatch(changeCampaignDetails({ key: 'editedAdData', value: {
			cta: params.row.cta || 'NONE',
			description: params.row.description || '',
			headline: params.row.headline || '',
			adID: params.row.adId,
			id: params.row.id,
		}}));
		dispatch(changeCampaignDetails({ key: 'editSettingName', value: 'editAdData' }));
		dispatch({ type: 'campaignDetails/openDialog' });
	};

	const editButton = (params: object) => (
		<IconButton
			id={`edit-image-button-${params.row.id}`}
			size="small"
			onClick={() => editAdData(params)}
			disabled={params.row.textStatus && params.row.textStatus?.toUpperCase() === 'REJECTED'}
		>
			<Edit className="edit-image-button-icon" />
		</IconButton>
	);

	const columns = [
		{
			field: 'status', headerName: 'Status', width: 120,
			cellClassName: 'ad-status--cell',
			valueFormatter: (value) => value ? 'Active' : 'Disabled', renderCell: (params) => params.row.id ? statusToggle(params) : '',
		},
		{
			field: 'edit', headerName: 'Edit', width: 70, renderCell: (params) => params.row.id ? editButton(params) : '',
		},
		{
			field: 'image', headerName: 'Image', width: 124, renderCell: (params) => params.row.id ? <ImageCell url={params.value} /> : '',
		},
		{
			field: 'headline', headerName: 'Headline', width: 250,
		},
		{
			field: 'description', headerName: 'Description', width: 250,
		},
		{
			field: 'cta', headerName: 'CTA', width: 150, type: 'number',
		},
		{
			field: 'videoThumbnail', headerName: 'Video Thumbnail', width: 124, renderCell: (params) => params.row.id ? <ImageCell url={params.value} /> : '',
		},
		...commonColumns(
			[
				'impressions',
				'visits',
				'clicks',
				'presell_ctr',
				'conversions',
				'average_cpc',
				'epc',
				'ctr',
				'spend',
				'cvr',
				'cpa',
				'revenue',
				'profit',
				'profit_percent',
			],
		),
		{
			field: 'networkImageId', headerName: 'Network Image ID', width: 180, renderCell: params => params.row.id ? <ClipboardCell content={params.value} /> : '',
		},
	] satisfies GridColDef[];

	const allColumns = [
		{
			field: 'status', headerName: 'Status', width: 120,
			cellClassName: 'ad-status--cell',
			valueFormatter: (value) => value ? 'Active' : 'Disabled', renderCell: (params) => statusToggle(params),
		},
		{
			field: 'edit', headerName: 'Edit', width: 70, renderCell: (params) => params.row.id ? editButton(params) : '',
		},
		{
			field: 'image', headerName: 'Image', width: 124, renderCell: (params) => <ImageCell url={params.value} />,
		},
		{
			field: 'videoThumbnail', headerName: 'Video Thumbnail', width: 124, renderCell: (params) => <ImageCell url={params.value} />,
		},
		{
			field: 'headline', headerName: 'Headline', width: 300,
		},
		{
			field: 'description', headerName: 'Description', width: 350,
		},
		{
			field: 'cta', headerName: 'CTA', width: 150,
		},
		{
			field: 'adId', headerName: 'Network Image ID', width: 180, renderCell: params => params.row.id ? <ClipboardCell content={params.value} /> : '',
		},
	] satisfies GridColDef[];

	const asyncColumns = useMemo(() => {
		if (all) return {
			...conditionalEditAdColumn(network),
		}
		return {
			...visitsAndClicks(estimatedSpends, advancedTracking, maximusLinks),
			...conditionalPresellCTR(advancedTracking, maximusLinks),
			...conditionalEditAdColumn(network),
		}
	}, [advancedTracking, all, estimatedSpends, maximusLinks])

	return (
		<div className="campaign-details-report">
			<ReportTable
				columnVisibility={asyncColumns}
				columns={all ? allColumns : columns}
				tableName={all ? 'All Images' : 'Images'}
				endpoint={all ? 'api/v1/campaign/reporting/ad/all': 'api/v1/campaign/reporting/ad'}
				aggregationModel={{
					clicks: 'sum',
					visits: 'sum',
					impressions: 'sum',
					conversions: 'sum',
					average_cpc: 'avg',
					presell_ctr: 'avg',
					cpc: 'avg',
					cpa: 'avg',
					cvr: 'avg',
					ctr: 'avg',
					epc: 'avg',
					spend: 'sum',
					revenue: 'sum',
					profit: 'sum',
					profit_percent: 'profitPercent',
					status: 'blankColumn',
					edit: 'blankColumn',
					image: 'blankColumn',
					videoThumbnail: 'blankColumn',
					headline: 'blankColumn',
					description: 'blankColumn',
					adId: 'blankColumn',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
				}}
			/>
		</div>
	)
}
