import { useState } from 'react';
import {CircularProgress} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Check from '../../../../Icons/Check.tsx';
import networkRequest from '../../../../utils/networkRequest.ts';
import { useAppDispatch, useAppSelector } from '../../../../hooks.ts';
import { daysToIDMapping } from '../Pages/DayParting/dayPartingHelper.ts';
import validateStep, {ValidateStepProps} from './StepsValidation.tsx';
import {Ad, changeNonField, resetAddCampaign} from '../../../../Redux/Slices/addCampaign.ts';
import {openSnackbar} from "../../../../Redux/Slices/main";
import {useErrorHelper} from "../../../../utils/useErrorHelper";
import { isOutbrain, isTaboola } from '@/utils/network-utils.ts';
import { parseCreativesPayload } from '../../AddCreatives/helpers.ts';
import { Dicer } from '../Pages/Ads/useAiGenerate';

export const modifyDayPartingForParams = (dayParting: object[]) => (
	dayParting.map((item) => ({
		...item,
		days: item.days.map((day: string) => daysToIDMapping[day]),
	})));

export const modifyAdsForParams = (ads: Ad[], isOtto = false) => (
	ads.map((ad) => ({
		imageUrl: ad.image,
		dicerFile: ad.dicerFile,
		fileType: ad.fileType,
		title: ad.headline,
		description: ad.description,
		cta: ad.callToAction,
		thumbnail: ad.thumbnail || undefined,
		dicerOffer: isOtto ? Dicer.offerId.otto : Dicer.offerId.vibriance,
		creativeID: (ad.addToCreatives ? ad.uuid : ad.creativeId) || undefined,
	})));

export default function SubmitCampaign() {
  const dispatch = useAppDispatch();
	const errorHelper = useErrorHelper();
  const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
  const addCampaignState = useAppSelector((state) => state.addCampaign);
  const ads = useAppSelector((state) => state.addCampaign.ads);
  const { network } = useAppSelector((state) => state.campaignDetails);

  const combineParams = () => {
    const {
      settings, fireAndForget, ads, dayParting,
      link, presellLinks, landingPageLinks,
      advancedTracking, estimatedSpends, maximusDayParting, networkDayParting,
    } = addCampaignState;
    return {
			...settings,
      name: settings.campaignName,
      network,
			groupID: settings.group,
      trafficAllocationMode: settings.creativeTrafficAllocation,
      advancedTracking: advancedTracking,
      estimatedSpends,
			browserTargeting: isTaboola(network) ? settings.browserTargeting[0] : settings.browserTargeting,
      timeframe: settings.conversionCapTimeframe,
      conversionCap: settings.conversionCapTimeframe !== '0' ? settings.conversionCapThreshold : '0',
      isOtto: settings.isOttoCampaign,
      splitByDevice: settings.platformTargeting.includes('0') ? settings.splitByDevice : false,
			bidStrategy: isOutbrain(network) ? settings.optimizationType : settings.bidStrategy,
      ...fireAndForget,
      ads: modifyAdsForParams(ads, settings.isOttoCampaign),
      maximusDayParting,
      dayParting: maximusDayParting ? modifyDayPartingForParams(dayParting) : [],
      networkDayParting: !maximusDayParting ? modifyDayPartingForParams(networkDayParting) : [],
      link,
      presellLinks,
      landingPageLinks,
    };
  };

  const createCampaign = async() => {
    setLoading(true);
    networkRequest('api/v1/campaign/create', combineParams(), 'POST')
    .then((response) => response.json())
    .then((response) => {
        if (response && typeof response === 'object') {
          if (response.success) {
						dispatch(openSnackbar({children: 'Campaign Successfully Created', severity: 'success'}));
						dispatch(resetAddCampaign({ network }));
						navigate('/');
					} else if (response.errors) {
						errorHelper(response.errors);
					} else {
						errorHelper('Something went wrong. Please check your settings and try again.');
					}
        }
        setLoading(false);
      }
    )
    .catch(() => {
      setLoading(false);
    });
  };

	function saveCreatives() {
		setLoading(true)
		const creatives = parseCreativesPayload(ads.filter(ad => !!ad.addToCreatives))
		return networkRequest('api/v1/campaign/creatives/add', { creatives })
			.then(response => response?.json())
			.then(data => {
				if (!data?.success) throw new Error('Submit error')
				dispatch(
					openSnackbar({ children: 'New creatives were saved successfully!', severity: 'success' })
				)
			})
			.catch(() => {
				dispatch(
					openSnackbar({ children: 'There was an error saving the creatives.', severity: 'error' })
				)
			})
	}

  const validateAds = () => {
    const newErrors: ValidateStepProps = validateStep(5, addCampaignState, network);
    dispatch(changeNonField({ key: 'errors', value: newErrors }));
		const valid = !Object.keys(newErrors.fields).length;
		if (!valid) {
			const children = <ul>{Object.keys(newErrors.fields).map((key: string, i: number) => (
				<li key={`error-${i}`}>{newErrors.fields[key]}</li>))}</ul>
			dispatch(openSnackbar({ children, severity: 'error' }));
		}
    return valid;
  };

  const submit = async () => {
    if (validateAds()) {
			if (ads.find(ad => !!ad.addToCreatives)) {
				await saveCreatives();
			}
      return createCampaign();
    }
		return false;
  };

  return (
    <button type="button" onClick={submit} className="stepper-submit-button" disabled={loading}>
			{loading
				? <CircularProgress size={40} />
				: <Check/>
			}
    </button>
  );
}
