import {
	combineReducers,
	configureStore,
} from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import slices from './Slices';

const rootReducer = combineReducers({
	...slices
});

const middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
	const logger = store => next => action => {
		const result = next(action)
		console.log('redux update', { ...action, nextState: store.getState() })
		return result
	}
  middleware.push(logger);
}

export const store = configureStore({
	reducer: rootReducer,
	middleware: () => middleware,
	devTools: true,
});

export function setupStore(preloadedState?: Partial<RootState>) {
	return configureStore({
		reducer: rootReducer,
		preloadedState,
	});
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = ReturnType<typeof setupStore>['dispatch'];
