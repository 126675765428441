import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { commonColumns } from '../../../../../utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import { CurrencyComparator } from '../../../../../utils/tables-utils.tsx';
import { formatCurrency } from '../../../../../utils/formatNumbers.ts';
import { useAppSelector } from '../../../../../hooks.ts';
import { isRevContent, Network } from '../../../../../utils/network-utils.ts';
import { estimatedReportsDisclaimer } from '@/Components/Pages/CampaignDetails/Reports/Collection/shared/helper.tsx';

const columns = (network: Network): GridColDef[] => {
	const listOfColumns = [
		{
			field: 'clickDate',
			headerName: 'Click Date',
			width: 190,
			type: 'timestamp',
		},
		{
			field: 'conversionDate',
			headerName: 'Conversion Date',
			width: 190,
			type: 'timestamp',
		},
		{
			field: 'siteName',
			headerName: 'Site Name',
			width: 230,
			renderCell: (params: GridRenderCellParams) => (
				<a target="_blank" className="manage-name-link" href={`https://${params.value}`}>
					{params.value}
				</a>
			),
		},
		...commonColumns(['cpc']),
		{
			field: 'payout',
			headerName: 'Payout',
			width: 100,
			type: 'number',
			sortComparator: CurrencyComparator,
			renderCell: (params: GridRenderCellParams) => formatCurrency(params.value),
		},
		{
			field: 'ip',
			headerName: 'IP',
			width: 160,
		},
		{
			field: 'clickID',
			headerName: 'Click ID',
			width: 350,
		},
		{
			field: 'userAgent',
			headerName: 'User Agent',
			width: 500,
		},
	];
	if (isRevContent(network)) {
		const widgetIDColumn = { field: 'widgetID', headerName: 'Widget ID', width: 170 };
		listOfColumns.splice(2, 1, widgetIDColumn);
	}
	return listOfColumns;
};

export default function Conversions() {
	const { network, estimatedSpends } = useAppSelector(state => state.campaignDetails);
	return (
		<div className="campaign-details-report">
			{!estimatedSpends && estimatedReportsDisclaimer}
			<ReportTable
				columns={columns(network)}
				tableName="Conversions"
				endpoint="api/v1/campaign/reporting/conversions"
			/>
		</div>
	);
}
