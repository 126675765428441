import SettingsDialog from "./SettingsDialog";
import {useAppSelector} from "@/hooks.ts";
// Edit Links
import EditLinks from "./EditLinks";
import { useSubmitLinks, useConfirmLinks } from './EditLinks/useSubmitLinks';
// Taboola Settings
import CampaignSettings from './CampaignSettings';
import { checkConfirmBudget, useDisableFFStatusConfirm, useSubmitSettings } from './CampaignSettings/useSubmitSettings';
import EditCampaignDetailsTitle from './CampaignSettings/Title.tsx';
// Fire & Forget
import FireAndForgetSettings from "./FireAndForgetSettings.tsx";
import {useSubmitFireAndForget} from "./FireAndForgetSettings.tsx/useSubmitFireAndForget";
// Day Parting
import DayPartingSettings from "./DayPartingSettings.tsx";
import {useSubmitDayParting} from "./DayPartingSettings.tsx/useSubmitDayParting";
// Ads
import AddAds from './AddAds';
import { useSubmitAds } from './AddAds/useSubmitAds';
// Conversion Cap
import ConversionCap from './ConversionCap';
import { useSubmitConversionCap } from './ConversionCap/useSubmitConversionCap.tsx';
// Reassign Campaign
import ReassignCampaign from './ReassignCampaign';
import { useSubmitReassignCampaign } from './ReassignCampaign/useSubmitReassignCampaign.tsx';
// Update Ad Data
import EditAdData from './EditAdData';
import { useSubmitUpdateAd } from './EditAdData/useSubmitUpdateAd.tsx';
import CampaignProcessingDialog from './CampaignProcessingDialog.tsx';
// Start Link Test
import StartLinkTest from './StartLinkTest';
import { useSubmitStartLinkTest } from './StartLinkTest/useSubmitStartLinkTest.tsx';
import { memo } from 'react';

const MemoizedEditLinks = memo(EditLinks)
const MemoizedEditCampaignDetailsTitle = memo(EditCampaignDetailsTitle)
const MemoizedCampaignSettings = memo(CampaignSettings)
const MemoizedFireAndForgetSettings = memo(FireAndForgetSettings)
const MemoizedConversionCap = memo(ConversionCap)
const MemoizedReassignCampaign = memo(ReassignCampaign)
const MemoizedDayPartingSettings = memo(DayPartingSettings)
const MemoizedAddAds = memo(AddAds)
const MemoizedEditAdData = memo(EditAdData)
const MemoizedStartLinkTest = memo(StartLinkTest)

export default function Settings() {
	const { campaignID, editSettingName, networkCampaignID, settingsDialogOpen } = useAppSelector((state) => state.campaignDetails);

	const isCampaignProcessed = networkCampaignID && networkCampaignID !== 'NA' && networkCampaignID !== campaignID

	const settingsMapping = {
		editLinks: {
			title: 'Edit Links',
			component: <MemoizedEditLinks />,
			useConfirm: useSubmitLinks(),
			preConfirmDialog: useConfirmLinks,
		},
		campaignSettings: {
			title: <MemoizedEditCampaignDetailsTitle />,
			plainTextTitle: 'Edit Campaign Details',
			component: <MemoizedCampaignSettings />,
			useConfirm: useSubmitSettings(),
			preConfirmDialog: checkConfirmBudget,
			postConfirm: useDisableFFStatusConfirm(),
		},
		editFireAndForget: {
			title: 'Edit Fire & Forget™',
			component: <MemoizedFireAndForgetSettings />,
			useConfirm: useSubmitFireAndForget(),
		},
		conversionCap: {
			title: 'Update Conversion Cap',
			component: <MemoizedConversionCap />,
			useConfirm: useSubmitConversionCap(),
		},
		reassignCampaign: {
			title: 'Reassign Campaign',
			component: <MemoizedReassignCampaign />,
			useConfirm: useSubmitReassignCampaign(),
		},
		editDayParting: {
			title: 'Edit Day Parting',
			component: <MemoizedDayPartingSettings />,
			useConfirm: useSubmitDayParting(),
		},
		addAds: {
			title: 'Add Ads',
			component: <MemoizedAddAds />,
			useConfirm: useSubmitAds(),
		},
		editAdData: {
			title: 'Edit Ad Data',
			component: <MemoizedEditAdData />,
			useConfirm: useSubmitUpdateAd(),
		},
		startLinkTest: {
			title: 'Start Link Test',
			component: <MemoizedStartLinkTest />,
			useConfirm: useSubmitStartLinkTest(),
			smallSize: true,
			confirmButtonText: 'Confirm',
		},
	}

	const {
		title,
		plainTextTitle,
		component,
		useConfirm,
		preConfirmDialog,
		smallSize = false,
		confirmButtonText,
		postConfirm,
	} = editSettingName ? settingsMapping[editSettingName] : {};

	if (!editSettingName || !component) return ''

	if (!isCampaignProcessed && settingsDialogOpen) return <CampaignProcessingDialog title={title} />

	return (
		<SettingsDialog
			dialogTitle={title}
			plainTextTitle={plainTextTitle}
			handleConfirm={useConfirm}
			preConfirmDialog={preConfirmDialog || null}
			smallSize={smallSize}
			confirmButtonText={confirmButtonText}
			handlePostConfirm={postConfirm || null}
		>
			{component}
		</SettingsDialog>
	)
}
