import { store } from '@/Redux/reduxStore'
import { isOutbrain, isRevContent, isTaboola, Network } from '@/utils/network-utils'
import { PinturaDefaultImageWriterOptions } from '@pqina/pintura'

export type FileToUpload = {
	originalFile: File
	file: File
	error?: true
	warn?: true
	ok?: true
	reason?: string
}

const MB = 1024 * 1024
const GB = 1024 * MB

export function getVideoCover(file, seekTo = 0.0): Promise<Blob> {
	return new Promise((resolve, reject) => {
		const videoPlayer = document.createElement('video')
		videoPlayer.setAttribute('crossorigin', 'anonymous')
		videoPlayer.setAttribute('src', URL.createObjectURL(file))
		videoPlayer.load()
		videoPlayer.addEventListener('error', () => {
			reject('Error when loading video file. Please try again.')
		})
		videoPlayer.addEventListener('loadedmetadata', () => {
			if (videoPlayer.duration < seekTo) {
				reject('Video is too short. Please upload another one.')
				return
			}
			setTimeout(() => {
				videoPlayer.currentTime = seekTo
			}, 200)
			videoPlayer.addEventListener('seeked', () => {
				const canvas = document.createElement('canvas')
				canvas.width = videoPlayer.videoWidth
				canvas.height = videoPlayer.videoHeight
				const ctx = canvas.getContext('2d')!
				ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
				ctx.canvas.toBlob(
					blob => {
						resolve(blob!)
					},
					'image/jpeg',
					1
				)
			})
		})
	})
}

export const getImageObj = (file: File): Promise<{ height: number; width: number }> => {
	return new Promise(resolve => {
		const img = new Image()
		const reader = new FileReader()
		reader.onload = event => {
			img.src = event.target?.result || ''
		}

		reader.readAsDataURL(file)
		img.onload = () => {
			resolve({ height: img.height, width: img.width })
		}
	})
}

export const getVideoObj = (file: File): Promise<{ height: number; width: number }> => {
	return new Promise(resolve => {
		const video = document.createElement('video')
		const videoURL = URL.createObjectURL(file)

		video.addEventListener(
			'loadedmetadata',
			function () {
				resolve({ height: this.videoHeight, width: this.videoWidth })
			},
			false
		)
		video.src = videoURL
	})
}

export const getFileType = (file: File): 'image' | 'video' => {
	return file.type.match('video.*') || file.type.match('image/gif') ? 'video' : 'image'
}

const validateAspectRatio = (currentRatio: number, validRatios: number[]): boolean => {
	const tolerance = 0.01
	return validRatios.some(ratio => Math.abs(ratio - currentRatio) <= tolerance)
}

type ValidationResult = Promise<{
	reason: string
	autofix?: PinturaDefaultImageWriterOptions
	error?: boolean
} | null>

const validateImageFile = async (file: File, network: Network, outbrainVideoAds: boolean): ValidationResult => {
	const [type, extension] = file.type.toLowerCase().split('/')
	const { width, height } = await getImageObj(file)
	const { forceResize } = store.getState().addCampaign.adSetup

	if (isOutbrain(network) && outbrainVideoAds) {
		return {
			reason: 'Please upload a video if you chose video ads campaign.',
			error: true,
		}
	}

	if (isOutbrain(network)) {
		if (forceResize && (width !== 1200 || height !== 800)) {
			return {
				reason: '',
				autofix: { targetSize: { width: 1200, height: 800, fit: 'force', upscale: true } },
			}
		}

		if (width < 400 || height < 260) {
			return {
				reason: 'Looks like this image\'s dimensions are too small. Please make sure that the dimensions are at least 600x400 pixels and try again',
				autofix: { targetSize: { width: 400, height: 260, fit: 'cover', upscale: true } },
			}
		}

		if (!validateAspectRatio(width / height, [1.5 / 1])) {
			return {
				reason:
					"The image's ratio should be 1.5:1. Please upload another image with the supported format.",
			}
		}
	}

	if (isRevContent(network)) {
		if (file.size > 2 * MB) {
			return {
				reason: 'Image file must be under 2MB. Please upload another image.',
				autofix: { mimeType: 'image/jpeg', quality: 0.8 },
			}
		}
		if (!['jpg', 'jpeg', 'png', 'webp'].includes(extension)) {
			return {
				reason: 'Valid image formats are .jpg, .jpeg, .png and .webp. Please upload another image.',
				autofix: { mimeType: 'image/jpeg' },
			}
		}
		if (forceResize && (width !== 1000 || height !== 750)) {
			return {
				reason: '',
				autofix: { targetSize: { width: 1000, height: 750, fit: 'force', upscale: true } },
			}
		}
		if (width < 500 || height < 375) {
			return {
				reason:
					"Looks like this image's dimensions are too small. Please make sure that the dimensions are at least 500px x 375px and try again",
				autofix: { targetSize: { width: 500, height: 375, fit: 'cover', upscale: true } },
			}
		}
		if (!validateAspectRatio(width / height, [4 / 3])) {
			return {
				reason:
					"The image's ratio should be 4:3. Please upload another image with the supported format.",
			}
		}
	}

	if (isTaboola(network)) {
		if (file.size > 5 * MB) {
			return {
				reason:
					"The image you've uploaded is too large. Please make sure your image is less than 5MB in size and try again.",
				autofix: { mimeType: 'image/jpeg', quality: 0.8 },
			}
		}
		if (!['jpg', 'jpeg', 'bmp', 'png', 'gif'].includes(extension)) {
			return {
				reason:
					'Valid image formats for Taboola are .jpg, jpeg, .bmp, .png and .gif. Please upload another image with the supported formats.',
				autofix: { mimeType: 'image/jpeg' },
			}
		}
		if (extension.match('gif') && (width < 500 || height < 375)) {
			return {
				reason:
					"Looks like this image's dimensions are too small. Please make sure that the dimensions are at least 500x375 pixels and try again",
				error: true,
			}
		}
		if (forceResize && (width !== 1200 || height !== 674)) {
			return {
				reason: '',
				autofix: { targetSize: { width: 1200, height: 674, fit: 'force', upscale: true } },
			}
		}
		if (width < 600 || height < 400) {
			return {
				reason:
					"Looks like this image's dimensions are too small. Please make sure that the dimensions are at least 600x400 pixels and try again",
				autofix: { targetSize: { width: 600, height: 400, fit: 'cover', upscale: true } },
			}
		}
		if (!validateAspectRatio(width / height, [16 / 9, 4 / 3, 1 / 1])) {
			return {
				reason:
					"The image's ratio should be 16:9, 4:3, or 1:1. Please upload another image with the supported format.",
			}
		}
	}

	return null
}

const validateVideoFile = async (file: File, network: Network, outbrainVideoAds: boolean): ValidationResult => {
	const [type, extension] = file.type.toLowerCase().split('/')
	const { width, height } = await getVideoObj(file)

	if (isRevContent(network)) {
		return {
			reason: 'RevContent does not support video ads. Please upload an image.',
			error: true,
		}
	}

	if (isOutbrain(network) && !outbrainVideoAds) {
		return {
			reason: 'Please upload an image or choose video ads campaign to upload videos for this campaign.',
			error: true,
		}
	}

	if (!['mp4', 'quicktime'].includes(extension)) {
		return {
			reason:
				'Valid video formats are .mp4 and .mov. Please upload another video with the supported formats.',
			error: true,
		}
	}
	if (file.size > 51 * MB) {
		return {
			reason:
				"The video you've uploaded is too large. Please make sure your video is less than 51MB in size and try again.",
			error: true,
		}
	}
	if (width < 1067 || height < 600) {
		return {
			reason:
				"Looks like this video's dimensions are too small. Please make sure that this video is at least 1067x600px and try again",
			error: true,
		}
	}

	return null
}

export const validateFile = async (file: File, network: Network, outbrainVideoAds: boolean): ValidationResult => {
	const [type] = file.type.toLowerCase().split('/')

	if (type === 'image') {
		return validateImageFile(file, network, outbrainVideoAds)
	}

	if (type === 'video') {
		return validateVideoFile(file, network, outbrainVideoAds)
	}

	return {
		reason: 'Invalid file type. Please upload an image or video file.',
		error: true,
	}
}
