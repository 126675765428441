import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { commonColumns } from '@/utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import { useAppSelector } from '../../../../../hooks.ts';
import { isRevContent, Network } from '../../../../../utils/network-utils.ts';
import { estimatedReportsDisclaimer } from '@/Components/Pages/CampaignDetails/Reports/Collection/shared/helper.tsx';

const columns = (network: Network): GridColDef[] => {
	const listOfColumns = [
		{
			field: 'clickDate',
			headerName: 'Timestamp',
			width: 190,
			type: 'timestamp',
		},
		{
			field: 'siteName',
			headerName: 'Site Name',
			width: 220,
		},
		...commonColumns(['cpc']),
		{
			field: 'ip',
			headerName: 'IP',
			width: 160,
		},
		{
			field: 'userAgent',
			headerName: 'User Agent',
			width: 500,
		},
	];
	if (isRevContent(network)) {
		const widgetIDColumn = { field: 'widgetID', headerName: 'Widget ID', width: 200 };
		listOfColumns.splice(1, 1, widgetIDColumn);
	}
	return listOfColumns;
};

export default function Clicks() {
	const { network, estimatedSpends } = useAppSelector(state => state.campaignDetails);
	return (
		<div className="campaign-details-report">
			{!estimatedSpends && estimatedReportsDisclaimer}
			<ReportTable columns={columns(network)} tableName="Clicks" endpoint="api/v1/campaign/reporting/clicks" />
		</div>
	);
}
