import { Autocomplete, Chip, TextField } from '@mui/material';

type FreeTextChipSelectProps = {
	label: string;
	error?: string;
	value: string[];
	onValueChange: (newValue: string[]) => void;
};

export function FreeTextChipSelect({ label, error, value, onValueChange }: FreeTextChipSelectProps) {
	return (
		<Autocomplete
			multiple
			freeSolo
			autoSelect
			selectOnFocus
			options={[]}
			value={value}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => {
					const { key, ...tagProps } = getTagProps({ index });
					return <Chip size="small" label={option} key={key} {...tagProps} />;
				})
			}
			renderInput={params => (
				<TextField {...params} error={!!error} helperText={error} variant="filled" label={label} />
			)}
			onInputChange={(e, value) => {
				if (value.endsWith(',') || value.endsWith(' ')) {
					(e.target as HTMLInputElement).blur();
					(e.target as HTMLInputElement).focus();
				}
			}}
			onChange={(_, value) => {
				const parsedValues = value.flatMap(v => v.split(/[, ]+/).map(v => v.trim().toLowerCase())).filter(Boolean);
				const uniqueValues = [...new Set(parsedValues)].sort();
				onValueChange(uniqueValues);
			}}
		/>
	);
}
