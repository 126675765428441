import { PREDEFINED_RANGES } from '@/Components/Filters/DateRange'
import DateRangeBase from '@/Components/Filters/DateRangeBase'
import { FreeTextChipSelect } from '@/Components/FormComponents/FreeTextChipSelect'
import MaximusLoader from '@/Components/Loaders/MaximusLoader'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { Ad, changeNonField } from '@/Redux/Slices/addCampaign'
import networkRequest, { useCancellableNetworkRequest } from '@/utils/networkRequest'
import { Button, TextField } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import Select from '../AddCampaign/Fields/Select'
import { parseCreativesResponse } from './helpers'
import CreativesListPicker from './ListPicker'

const getDefaultDateFilters = () => {
	const data = PREDEFINED_RANGES.all_time.range()
	return {
		startDate: data.startDate.toJSON(),
		endDate: data.endDate.toJSON(),
		range: data.range,
	}
}

function getDefaultFilters() {
	return {
		keywords: [] as string[],
		group: '0',
		search: '',
		...getDefaultDateFilters(),
	}
}

export default function CreativesSearchForm() {
	const cancellableRequest = useCancellableNetworkRequest()
	const dispatch = useAppDispatch()
	const ads = useAppSelector(state => state.addCampaign.ads)
	const adsSnapshot = useAppSelector(state => state.addCampaign.adsSnapshot)
	const network = useAppSelector(state => state.campaignDetails.network)
	const [selectedCreatives, setSelectedCreatives] = useState<string[]>([])
	const [creativesSnapshot, setCreativesSnapshot] = useState<(Ad & { searchTerm: string })[]>([])
	const [loading, setLoading] = useState(false)
	const [filters, setFilters] = useState(getDefaultFilters())
	const [groupOptions, setGroupOptions] = useState<{ value: string; label: string }[]>([])
	const [groupOptionsLoading, setGroupOptionsLoading] = useState(false)

	function handleSubmit() {
		const newAds = creativesSnapshot.filter(ad => selectedCreatives.includes(ad.uuid))
		dispatch(changeNonField({ key: 'ads', value: [...ads, ...newAds] }))
		dispatch(changeNonField({ key: 'adsSnapshot', value: [...adsSnapshot, ...structuredClone(newAds)] }))
	}

	function pullGroupOptions() {
		setGroupOptionsLoading(true)
		networkRequest('api/v1/user/group/pull', {}, 'POST')
			.then(response => response?.json())
			.then(data => {
				if (data?.length > 0) {
					setGroupOptions([
						{ value: '0', label: 'Please Select' },
						...data.map(group => ({ value: group.id, label: group.name })),
					])
				}
			})
			.finally(() => setGroupOptionsLoading(false))
	}

	async function fetchCreatives() {
		setLoading(true)
		setCreativesSnapshot([])
		setSelectedCreatives([])
		setFilters(getDefaultFilters())
		cancellableRequest('api/v1/campaign/creatives/pull', {})
			.then(response => response?.json())
			.then(data => {
				if (data?.length > 0) {
					const parsedData = parseCreativesResponse(data)
					setCreativesSnapshot(parsedData)
				}
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		pullGroupOptions()
		fetchCreatives()
	}, [])

	const filteredCreatives = useMemo(() => {
		return creativesSnapshot
			.filter(creative => {
				if (new Date(creative.createdAt!) < new Date(filters.startDate) || new Date(creative.createdAt!) > new Date(filters.endDate)) {
					return false
				}
				if (`${creative.network}` !== `${network}`) {
					return false
				}
				if (filters.keywords.length > 0) {
					if (
						!filters.keywords.some(keyword => (creative.keywords || []).map(k => k.toLowerCase()).includes(keyword))
					) {
						return false
					}
				}
				if (`${filters.group}` !== '0' && `${creative.usmID}` !== `${filters.group}`) {
					return false
				}
				if (filters.search.length > 0) {
					if (!creative.searchTerm.toLowerCase().includes(filters.search.toLowerCase())) {
						return false
					}
				}
				return true
			})
			.sort((a, b) => {
				const aSelected = selectedCreatives.includes(a.uuid)
				const bSelected = selectedCreatives.includes(b.uuid)
				if (aSelected === bSelected) return 0
				return aSelected ? -1 : 1
			})
	}, [creativesSnapshot, selectedCreatives, filters])

	return (
		<div>
			<div className="mb-2 mt-2 border-b font-semibold text-black/60">Filters</div>
			<div className="grid w-full gap-4 auto-fit-80">
				<DateRangeBase
					className="rounded-b-none border-b border-solid border-b-black/[0.42] bg-black/[0.06]"
					startDate={filters.startDate}
					endDate={filters.endDate}
					range={filters.range}
					onRangeChange={({ startDate, endDate, range }) => {
						setFilters({ ...filters, startDate, endDate, range })
					}}
				/>

				<FreeTextChipSelect
					label="Keywords"
					value={filters.keywords}
					onValueChange={keywords => setFilters({ ...filters, keywords })}
				/>

				{groupOptions.length > 2 && <Select
					label="Maximus Group"
					size={{ width: '100%' }}
					disabled={groupOptionsLoading}
					options={groupOptions}
					value={filters.group}
					onChange={e => setFilters({ ...filters, group: e.target.value })}
				/>}
			</div>

			<TextField
				variant="filled"
				className="mb-4 mt-3 w-full"
				label="Search"
				value={filters.search}
				onChange={e => setFilters({ ...filters, search: e.target.value })}
			/>

			<div className="mb-2 mt-3 border-b font-semibold text-black/60">Creatives</div>

			{loading ? (
				<MaximusLoader />
			) : (
				<CreativesListPicker
					creatives={filteredCreatives}
					selectedIds={selectedCreatives}
					onSelect={ad => {
						if (selectedCreatives.includes(ad.uuid)) {
							setSelectedCreatives(selectedCreatives.filter(uuid => uuid !== ad.uuid))
						} else {
							setSelectedCreatives([...selectedCreatives, ad.uuid])
						}
					}}
				/>
			)}

			<Button variant="contained" className="mx-auto mt-4 flex" disabled={!selectedCreatives.length} onClick={handleSubmit}>
				Add Selected Creatives
			</Button>
		</div>
	)
}
