import Toggle from '@/Components/FormComponents/Toggle'
import Input from '@/Components/Pages/AddCampaign/Fields/Input'
import Select from '@/Components/Pages/AddCampaign/Fields/Select'
import { networkOptions } from '@/Components/Pages/AddCreatives/helpers'
import { useAppDispatch, useAppSelector } from '@/hooks'
import Add from '@/Icons/Add'
import Minus from '@/Icons/Minus'
import Sparkles from '@/Icons/Sparkles'
import Warning from '@/Icons/Warning'
import { Ad, changeAdSetup, changeNonField } from '@/Redux/Slices/addCampaign'
import { changeCampaignDetails } from '@/Redux/Slices/campaignDetails'
import { isRevContent, isTaboola, Network} from '@/utils/network-utils'
import { networkRequestMultipart } from '@/utils/networkRequest'
import { formatTokens, tokenMap } from '@/utils/token-utils.ts'
import { useErrorHelper } from '@/utils/useErrorHelper'
import { Alert, Button, CircularProgress, IconButton, Tooltip } from '@mui/material'
import capitalize from 'lodash/capitalize'
import { useEffect,useState } from 'react'
import { callToActionOptions } from './CallToActionOptions.tsx'
import UploadAdsV2 from './UploadAdsV2'
import { getFileType,getVideoCover } from './UploadAdsV2/validation'
import { useAiGenerate } from './useAiGenerate.ts'
import { FreeTextChipSelect } from '@/Components/FormComponents/FreeTextChipSelect'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'

const generateAds = (images: Array<object>, headlines: Array<string>, descriptions: Array<string>, callToAction: string, ai: string[], keywords?: string[], network?: Network, addToCreatives?: boolean) => {
	const result: Ad[] = [];
	if (!descriptions.length) descriptions.push('')
	images.forEach(({value: image, dicerFile, fileType, thumbnail}) => {
		headlines.forEach((headline) => {
			descriptions.forEach((description) => {
				if (image && headline && callToAction) {
					const ad = {
						image,
						dicerFile,
						headline,
						description,
						callToAction: fileType === 'video' ? 'NONE' : callToAction,
						fileType,
						thumbnail,
						ai: {
							image: ai.includes('image') ? dicerFile : undefined,
							headline: ai.includes('headline') ? headline : undefined,
							description: ai.includes('description') ? description : undefined,
							callToAction: ai.includes('callToAction') ? callToAction : undefined,
						},
						keywords,
						network,
						uuid: crypto.randomUUID(),
						addToCreatives,
					};
					result.push(ad);
				}
			});
		});
	});
	return result;
}

type AdData = { value: string, uuid: string }

const emptyObj = (value = ''): AdData => ({
	value,
	uuid: crypto.randomUUID(),
});

const setValWithoutMutation = (array: Array<AdData>, value: string, uuid: string) => {
	const nonMutableArray = JSON.parse(JSON.stringify(array));
	const index = nonMutableArray.findIndex(({uuid: id}) => id === uuid);
	nonMutableArray[index].value = value;
	return nonMutableArray;
}

const removeValWithoutMutation = (array: Array<AdData>, value: string, uuid: string) => {
	const nonMutableArray = JSON.parse(JSON.stringify(array));
	const index = nonMutableArray.findIndex(({uuid: id}) => id === uuid);
	nonMutableArray.splice(index, 1);
	return nonMutableArray;
}

interface AdTextInputProps {
	type: string,
	dataList: Array<AdData>,
	maxSize: number,
	errors: Record<string, string>,
	useAi?: boolean,
	aiPrompt: string,
	setUseAi: (value: boolean) => void,
	onPromptChange: (value: string) => void,
	onAdd: () => void,
	onBlur: () => void,
	onChange: (value: Array<AdData>) => void,
	onRemove: (value: number) => void,
	showAI?: boolean,
}

function AdTextInput({
	type,
	dataList,
	maxSize,
	errors,
	useAi,
	aiPrompt,
	setUseAi,
	onPromptChange,
	onAdd,
	onBlur,
	onChange,
	onRemove,
	showAI = true,
}: AdTextInputProps) {
	const handleChange = (value: string, uuid: string) => {
		let newArray;
		if (value === '' && dataList.length > 1 && uuid !== dataList[dataList.length - 1].uuid) {
			newArray = removeValWithoutMutation(dataList, value, uuid);
		} else {
			newArray = setValWithoutMutation(dataList, value, uuid);
		}
		onChange(newArray);
	};

	return <div className="w-full">
		<div className="mb-2 mt-6 h-9 border-b font-semibold text-black/60">
			{capitalize(`${type}s`)}
			{showAI !== false &&
				<Toggle className='ml-8' label="AI Fill" value={!!useAi} onChange={() => {
					if (useAi) onPromptChange?.('')
					setUseAi?.(!useAi)
				}}/>
			}
		</div>

		{useAi ? (
			// <Input
			// 	multiline
			// 	inputSize='small'
			// 	size={{mb: 2, width: '100%'}}
			// 	name={`${type}-prompt`}
			// 	label={`AI ${capitalize(type)}s Prompt`}
			// 	error={!!errors[`ai${capitalize(type)}s`]}
			// 	helperText={errors[`ai${capitalize(type)}s`]}
			// 	value={aiPrompt}
			// 	onChange={({target: {value}}) => onPromptChange?.(value)}
			// />
			<Alert severity="info">{capitalize(type)} prompt support is coming soon.</Alert>
		) : (<>
			{dataList.map(({value: adData, uuid}, index) => (
				<div key={uuid} className="add-campaign-field-row relative">
					<Input
						onChange={({target: {value}}) => handleChange(value, uuid)}
						onBlur={() => onBlur()}
						label={capitalize(type)}
						value={adData}
						size={{mb: 2, width: '100%'}}
						name={`${type}-${index}`}
						maxSize={maxSize}
						error={!!errors[`${type}-${uuid}`]}
						helperText={errors[`${type}-${uuid}`]}
						inputSize='small'
						multiline
					/>
					{index !== 0 && (
						<span
							className="remove-add-campaign-field-row"
							onClick={() => onRemove(index)}
						>
							<Minus/>
						</span>
					)}
				</div>
			))}

			<div className="flex items-center cursor-pointer" onClick={onAdd}>
				<IconButton
					id={`add-${type}-button`}
					className="add-ad-text-button"
				>
					<Add className="fill-white"/>
				</IconButton>
				New {capitalize(type)}
			</div>
		</>)}
	</div>
}

export default function AddAdsSection({ isCreatives = false }) {
	const dispatch = useAppDispatch();
	const errorHelper = useErrorHelper();
	const ads = useAppSelector((state) => state.addCampaign.ads);
	const adsSnapshot = useAppSelector((state) => state.addCampaign.adsSnapshot);
	const outbrainVideoAds = useAppSelector((state) => state.addCampaign.outbrainVideoAds);
	const { validFilesToUpload } = useAppSelector((state) => state.addCampaign.adSetup);
	const network = useAppSelector((state) => state.campaignDetails.network);
	const [keywords, setKeywords] = useState([] as string[]);
	const [headlines, setHeadlines] = useState([emptyObj()]);
	const [descriptions, setDescriptions] = useState([emptyObj()]);
	const [callToAction, setCallToAction] = useState<string>('NONE');
	const [lastErrors, setLastErrors] = useState<Record<string, string>>({});
	const [isUploading, setIsUploading] = useState(false);
	const [useAi, setUseAi] = useState({ images: false, headlines: false, descriptions: false })
	const [aiPrompts, setAiPrompts] = useState({ images: '', headlines: '', descriptions: '' })
	const [aiVariants, setAiVariants] = useState('3')
	const aiGenerate = useAiGenerate()

	const aiEnabled = useAi.images || useAi.headlines || useAi.descriptions;

	useEffect(() => {
		if (aiEnabled) {
			setKeywords([...new Set([...keywords, 'ai'])].sort());
		} else {
			setKeywords(current => current.filter(k => k !== 'ai'));
		}
	}, [aiEnabled]);

	useDebouncedEffect(() => {
		const filteredErrors = Object.entries(lastErrors).filter(([key]) => !key.includes('headline'));
		setLastErrors(Object.fromEntries(filteredErrors));
	}, [headlines], 50);

	useDebouncedEffect(() => {
		const filteredErrors = Object.entries(lastErrors).filter(([key]) => !key.includes('description'));
		setLastErrors(Object.fromEntries(filteredErrors));
	}, [descriptions], 50);

	useDebouncedEffect(() => {
		const filteredErrors = Object.entries(lastErrors).filter(([key]) => key !== 'images');
		setLastErrors(Object.fromEntries(filteredErrors));
	}, [validFilesToUpload], 50);

	const adErrors = () => {
		const errors: Record<string, string> = {}
		// COMMENTING THIS OUT BECAUSE APPARENTLY THE PROMPT IS NOT MANDATORY.
		// SENDING AN EMPTY STRING SHOULD JUST LET THE AI GENERATE IT ON ITS OWN.
		// ****
		// if (useAi.images && !aiPrompts.images) {
		// 	errors.aiImages = 'AI Prompts should be at least 1 character long'
		// }
		// ****
		// if (useAi.headlines && !aiPrompts.headlines) {
		// 	errors.aiHeadlines = 'AI Prompts should be at least 1 character long'
		// }
		// if (useAi.descriptions && !aiPrompts.descriptions) {
		// 	errors.aiDescriptions = 'AI Prompts should be at least 1 character long'
		// }
		if (!useAi.images && !validFilesToUpload.length) {
			errors.images = 'Please upload at least one valid image'
		}
		if (!useAi.headlines) {
			for (const headline of headlines) {
				if (headline.value) continue;
				errors[`headline-${headline.uuid}`] = 'All Headlines should be at least 1 character long'
			}
		}
		if (isCreatives && !keywords.length) {
			errors.keywords = 'New Creatives must have at least one keyword'
		}
		return errors;
	};

	const getInputErrors = () => {
		const errors: Record<string, string> = {}
		for (const input of headlines) {
			const _errors = formatTokens(input.value, network).errors
			if (_errors.length) {
				errors[`headline-${input.uuid}`] = `Invalid headline tokens: ${_errors.join(', ')}`
			}
			const max = tokenMap[network].maxLength.headline
			if (input.value.length > max) {
				errors[`headline-${input.uuid}`] = `Headline exceeds character limit of ${max}`
			}
		}
		for (const input of descriptions) {
			const _errors = formatTokens(input.value, network).errors
			if (_errors.length) {
				errors[`description-${input.uuid}`] = `Invalid description tokens: ${_errors.join(', ')}`
			}
			const max = tokenMap[network].maxLength.description
			if (input.value.length > max) {
				errors[`description-${input.uuid}`] = `Description exceeds character limit of ${max}`
			}
		}
		return errors;
	};

	// TODO: remove repeats from UploadAds.tsx
	// TODO: move upload process to separate component
	const uploadFile = async (file: File, isThumbnail = false) => {
		const endpoint =
			isThumbnail || file.type.match('video.*') || file.type.match('image/gif')
				? 'api/v1/campaign/fileUpload/video'
				: 'api/v1/campaign/fileUpload/image'
		return networkRequestMultipart(endpoint, { file, network }, 'https://creatives.maximusx.app')
			.then(response => response.json())
			.catch(() => ({ url: '' }))
	}

	const createThumbnail = async (file: File) => {
		if (!file.type.match('video.*')) return { url: '' }
		const cover = await getVideoCover(file, 0.1)
		const newFile = new File([cover], 'videoThumbnail.jpg', { type: 'image/jpeg' })
		return await uploadFile(newFile, true)
	}

	const generateOnClick = async () => {
		const errors = {...adErrors(), ...getInputErrors()};
		setLastErrors(errors);
		if (Object.keys(errors).length) {
			errorHelper(Object.values(errors));
			return;
		}

		const parsedHeadlines = headlines.map(h => formatTokens(h.value, network).result).filter(Boolean)
		const parsedDescriptions = descriptions.map(d => formatTokens(d.value, network).result).filter(Boolean)

		setIsUploading(true)
		const images: { value: string, dicerFile?: string, fileType: string, thumbnail: string }[] = []
		const aiInputs: string[] = []

		if (aiEnabled) {
			const aiGen = await aiGenerate.fetchAiData({
				aspectRatio: isRevContent(network) ? '4:3' : '16:9',
				// descriptionsPrompt: aiPrompts.descriptions,
				descriptionsPrompt: useAi.descriptions ? '' : '',
				// headlinesPrompt: aiPrompts.headlines,
				headlinesPrompt: useAi.headlines ? '' : '',
				imagesPrompt: aiPrompts.images,
				useAi: useAi,
				variants: +aiVariants,
			})
			parsedHeadlines.push(...aiGen.headlines)
			parsedDescriptions.push(...aiGen.descriptions)
			images.push(...aiGen.images.map(({ dicerFile, image }) => ({
				value: image,
				dicerFile,
				fileType: 'image',
				thumbnail: '',
			})))
			if (useAi.images) aiInputs.push('image')
			if (useAi.headlines) aiInputs.push('headline')
			if (useAi.descriptions) aiInputs.push('description')
		}

		for (const file of validFilesToUpload) {
			const res = await uploadFile(file)
			const thumbnailRes = await createThumbnail(file)
			images.push({
				value: res.url,
				fileType: getFileType(file),
				thumbnail: thumbnailRes.url,
			})
		}

		const newAds = generateAds(images, parsedHeadlines, parsedDescriptions, callToAction, aiInputs, keywords, network, isCreatives);
		dispatch(changeNonField({key: 'ads', value: [...ads, ...newAds]}));
		dispatch(changeNonField({key: 'adsSnapshot', value: [...adsSnapshot, ...structuredClone(newAds)]}));
		setHeadlines([emptyObj()]);
		setDescriptions([emptyObj()]);
		setCallToAction('NONE');
		dispatch(changeAdSetup({ filesToUpload: [] }))
		setIsUploading(false)
	}

	const removeHeadline = (index: number) => {
		const newHeadlines = [...headlines];
		newHeadlines.splice(index, 1);
		setHeadlines(newHeadlines);
	}

	const removeDescription = (index: number) => {
		const newDescriptions = [...descriptions];
		newDescriptions.splice(index, 1);
		setDescriptions(newDescriptions);
	}

	return (
		<div className="w-full">
			<div className='opacity-0 max-h-0 transition-all -mt-6' style={aiEnabled ? { opacity: 1, maxHeight: 500, margin: 0 } : {}}>
				<section className="mt-4 flex items-center justify-center gap-3 rounded border border-red-500 bg-red-500/10 p-2">
					<Warning className="w-7 fill-red-600" />
					<span className="text-start text-xs font-medium leading-tight text-red-500">
						DO NOT USE GENERATE AI WITHOUT JOSH APPROVAL <br />
						ONLY FUNCTIONS FOR VIBRIANCE & OTTO (Auto)
					</span>
				</section>
			</div>

			{isCreatives && (<>
				<div className="mb-2 mt-6 h-7 border-b font-semibold text-black/60">
					Creatives Settings
				</div>

				<div className="grid auto-fit-80 gap-6">
					<Select
						label="Network"
						size={{ width: '100%' }}
						options={networkOptions}
						value={`${network}`}
						disabled={ads.length > 0}
						tooltip={ads.length > 0 ? 'Network selection is locked due to unsaved creatives. Remove or finish saving them to enable this option.' : ''}
						onChange={e => dispatch(changeCampaignDetails({ key: 'network', value: +e.target.value }))}
					/>

					<FreeTextChipSelect
						label="Keywords"
						error={lastErrors.keywords}
						value={keywords}
						onValueChange={newValue => {
							if (lastErrors.keywords) delete lastErrors.keywords;
							setKeywords(newValue);
						}}
					/>
				</div>
			</>)}

			<div className="mb-2 mt-6 h-9 border-b font-semibold text-black/60">
				{outbrainVideoAds ? 'Videos' : 'Images'}
				{!outbrainVideoAds &&
					<Toggle className='ml-10' label="AI Fill" value={useAi.images} onChange={e => {
						if (useAi.images) setAiPrompts({ ...aiPrompts, images: '' })
						setUseAi({ ...useAi, images: !useAi.images })
					}} />}
			</div>

			{useAi.images ? (
				<Input
					multiline
					size={{mb: 2, width: '100%'}}
					inputSize='small'
					name="images-prompt"
					label="AI Images Prompt"
					error={!!lastErrors.aiImages}
					helperText={lastErrors.aiImages}
					value={aiPrompts.images}
					onChange={e => setAiPrompts({ ...aiPrompts, images: e.target.value })}
				/>
			) : (
				<>
					<UploadAdsV2 />
					<span className="text-xs text-red-600">{lastErrors.images}</span>
				</>
			)}

			<div className="grid auto-fit-80 gap-x-6">
				<AdTextInput
					type='headline'
					dataList={headlines}
					maxSize={tokenMap[network].maxLength.headline}
					errors={lastErrors}
					useAi={useAi.headlines}
					aiPrompt={aiPrompts.headlines}
					setUseAi={headlines => setUseAi({ ...useAi, headlines })}
					onPromptChange={headlines => setAiPrompts({ ...aiPrompts, headlines })}
					onAdd={() => setHeadlines([...headlines, emptyObj()])}
					onBlur={getInputErrors}
					onChange={setHeadlines}
					onRemove={removeHeadline}
					showAI
				/>

				<AdTextInput
					type='description'
					dataList={descriptions}
					maxSize={tokenMap[network].maxLength.description}
					errors={lastErrors}
					useAi={false}
					aiPrompt={aiPrompts.descriptions}
					setUseAi={descriptions => setUseAi({...useAi, descriptions})}
					onPromptChange={descriptions => setAiPrompts({...aiPrompts, descriptions})}
					onAdd={() => setDescriptions([...descriptions, emptyObj()])}
					onBlur={getInputErrors}
					onChange={setDescriptions}
					onRemove={removeDescription}
					showAI={false}
				/>
			</div>

			{isTaboola(network) && (!validFilesToUpload.length || validFilesToUpload.some(file => getFileType(file) === 'image')) &&
				<>
					<div className="mb-2 mt-7 border-b font-semibold text-black/60">
						Call To Action
					</div>
					<div className="add-campaign-field-row">
						<Select
							onChange={({target: {value}}) => setCallToAction(value)}
							label="Call To Action"
							value={callToAction}
							name="add-campaign-cta"
							options={callToActionOptions}
							size={{width: '100%'}}
						/>
					</div>
				</>}

			{aiEnabled && <Select
				label="AI Variants Count"
				name="variants"
				size={{ width: '100%', my: 4 }}
				options={[
					{ label: '1', value: '1' },
					{ label: '2', value: '2' },
					{ label: '3', value: '3' },
					{ label: '4', value: '4' },
					{ label: '5', value: '5' },
				]}
				value={aiVariants}
				onChange={e => setAiVariants(e.target.value)}
			/>}

			<Tooltip title={`Click to generate ${isCreatives ? 'creatives' : 'ads'}`} placement="top">
				<Button
					disabled={isUploading}
					id="generate-ads"
					variant='contained'
					className="my-6 mx-auto flex"
					onClick={generateOnClick}
				>
					{isUploading
						? <CircularProgress size={20} color='inherit' sx={{ mr: 2 }} />
						: aiEnabled ? <Sparkles className='size-5 mr-2' /> : null}
					Generate {isCreatives ? 'Creatives' : 'Ads'} {aiEnabled && 'with AI'}
				</Button>
			</Tooltip>

			<div className="add-ads-token-text space-y-3">
				<p>
					<b>Allowed Keywords:</b> {tokenMap[network].keywords.join(', ')}
				</p>
				<p>
					<b>Expected Format:</b> {'{KEYWORD}'}
				</p>
				<p>
					<b>Example:</b> {'Car Dealerships in {city}, {country} Are Going Bankrupt Because Of This Free App.'}
				</p>
				<p>
					<b>Image requirements:</b> {{
					[Network.Taboola]: <>
						<br/>Minimum width: 600px <br/> Minimum height: 400px <br/> Aspect Ratio: 16:9, 4:3, 1:1 <br/>
						<br/> <b>Recommended Size:</b> <br/>
						1200x674px (16:9) <br/>
					</>,
					[Network.RevContent]: <>
						<br/>Minimum width: 500px <br/> Minimum height: 375px <br/> Aspect Ratio: 4:3 <br/>
						<br/> <b>Recommended Size:</b> <br/>
						1000x750px (4:3) <br/>
					</>,
					[Network.Outbrain]: <>
						<br/>Minimum width: 400px <br/> Minimum height: 260px <br/> Aspect Ratio: 1.5:1 <br/>
						<br/> <b>Recommended Size:</b> <br/>
						1200x800px (1.5:1) <br/>
					</>,
				}[network]}
				</p>
			</div>
		</div>
	);
}
