import { useAppDispatch, useAppSelector } from '@/hooks'
import Close from '@/Icons/Close'
import Edit from '@/Icons/Edit'
import Warning from '@/Icons/Warning'
import { changeAdSetup } from '@/Redux/Slices/addCampaign'
import { Tooltip } from '@mui/material'
import { useMemo } from 'react'
import FileData from './FileData'
import { FileToUpload } from './validation'

export default function FileList({
	items,
	onSetEditingImage,
}: {
	items: FileToUpload[]
	onSetEditingImage: (file: File) => void
}) {
	if (!items.length) return null

	return (
		<div className={`space-y-2 rounded border p-4`}>
			<div className={`flex flex-wrap gap-6`}>
				{items.map((item, index) => (
					<FileItem
						key={`${index}_${item.file.name}`}
						item={item}
						onSetEditingImage={onSetEditingImage}
					/>
				))}
			</div>
		</div>
	)
}

function FileItem({
	item,
	onSetEditingImage,
}: {
	item: FileToUpload
	onSetEditingImage: (file: File) => void
}) {
	const dispatch = useAppDispatch()
	const currentFiles = useAppSelector(state => state.addCampaign.adSetup.filesToUpload)

	const customWrapperClass = useMemo(() => {
		if (item.error) return 'border-red-600 bg-red-600/20'
		if (item.warn) return 'border-yellow-600 bg-yellow-600/20'
		return 'border-green-600 bg-green-600/20'
	}, [item])

	const customIconClass = useMemo(() => {
		if (item.error) return 'bg-red-500 hover:bg-red-600'
		if (item.warn) return 'bg-yellow-500 hover:bg-yellow-600'
		return 'bg-blue-500 hover:bg-blue-600 opacity-0 group-hover:opacity-100'
	}, [item])

	const Thumbnail = useMemo(
		() => (item.file.type.startsWith('image/') ? 'img' : 'video'),
		[item.file.type]
	)

	return (
		<>
			<div
				className={`group relative flex max-h-32 max-w-32 flex-col rounded border p-1 ${customWrapperClass}`}
			>
				<Thumbnail
					autoPlay
					loop
					muted
					className="h-full w-full object-contain"
					alt={item.file.name}
					src={URL.createObjectURL(item.file)}
				/>

				<Tooltip title={<FileData item={item} />} placement="top">
					<span
						className={`absolute -right-3 -top-3 m-0 flex size-6 cursor-pointer items-center justify-center rounded-full shadow-md transition-opacity ${customIconClass}`}
					>
						{item.ok ? (
							<i className="font-semibold text-white">i</i>
						) : (
							<Warning className="size-4 fill-white" />
						)}
					</span>
				</Tooltip>

				<footer className="absolute bottom-[-14px] right-1 flex gap-2 opacity-0 transition group-hover:opacity-100">
					{Thumbnail === 'img' && !item.error && (
						<Tooltip title="Click to edit">
							<div
								className="flex cursor-pointer items-center justify-center rounded-full bg-blue-500 p-1 shadow-md transition hover:bg-blue-600"
								onClick={() => {
									if (Thumbnail === 'img') onSetEditingImage(item.file)
								}}
							>
								<Edit className="size-4 fill-white" />
							</div>
						</Tooltip>
					)}

					<Tooltip title="Click to remove">
						<div
							className="flex cursor-pointer items-center justify-center rounded-full bg-red-500 p-1 shadow-md transition hover:bg-red-600"
							onClick={() => {
								dispatch(
									changeAdSetup({
										filesToUpload: currentFiles.filter(([_, f]) => f.name !== item.file.name),
									})
								)
							}}
						>
							<Close className="size-4 fill-white" />
						</div>
					</Tooltip>
				</footer>
			</div>
		</>
	)
}
