import { useAppDispatch, useAppSelector } from '@/hooks';
import { openSnackbar } from '@/Redux/Slices/main';
import { creativesRequest, networkRequestMultipart } from '@/utils/networkRequest';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

function dicerRequest(path: string, body: any) {
	return fetch(`https://dicerai.app.n8n.cloud/webhook/${path}`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			Authorization: '4uX6A2pUjcnVrG2AVFAjewEHDby3tvReLRMsRXkKZt4qD7hRwiFPc9ganFhoTiFd',
		},
	}).then(res => {
		if (!res.ok) {
			throw new Error('Failed to fetch');
		}
		return res.json();
	});
}

export const Dicer = {
	teamId: 'cm3px1en30000yh74d6pr4s0i',
	offerId: {
		otto: 'cc12ff37aa700bf18f2f319a2',
		vibriance: 'cm4cw2c3j0003bacpbo88ld5l',
	},
};

export function useAiGenerate() {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const network = useAppSelector(state => state.campaignDetails.network);
	const isOtto = useAppSelector(state => {
		if (location.pathname.includes('add_campaign')) {
			return state.addCampaign.settings.isOttoCampaign;
		}
		return state.campaignDetails.isOtto;
	});
	const [loading, setLoading] = useState(false);

	const uploadFile = async (url: string) => {
		const MAX_RETRIES = 5;

		const fetchWithRetry = async (attempt: number) => {
			try {
				const response = await creativesRequest('api/proxy', { url }, 'GET');
				if (!response.ok) throw new Error();
				return response;
			} catch (error) {
				if (attempt >= MAX_RETRIES) throw error;
				return fetchWithRetry(attempt + 1);
			}
		};

		const response = await fetchWithRetry(1).catch(() => {
			dispatch(
				openSnackbar({
					children:
						'Error uploading image. Please try uploading again and reach out to support if this problem continues',
					severity: 'error',
				})
			);
		});
		if (!response?.ok) return '';

		const blob = await response.blob();
		const file = new File([blob], url, { type: blob.type });

		return { url: file, dicerFile: url };
	};

	const uploadMaximusFile = async (url: string, dicerFile: string) => {
		return networkRequestMultipart(
			'api/v1/campaign/fileUpload/image',
			{ file: url, network },
			'https://creatives.maximusx.app'
		)
			.then(response => response.json())
			.then(res => ({ url: res.url, dicerFile }))
			.catch(() => {
				dispatch(openSnackbar({ children: 'Failed to upload external file', severity: 'error' }));
				return '';
			});
	};

	const fetchAiData = async (settings: {
		variants: number;
		descriptionsPrompt: string;
		headlinesPrompt: string;
		imagesPrompt: string;
		useAi: { images: boolean; headlines: boolean; descriptions: boolean };
		aspectRatio: '1:1' | '16:9' | '2:3' | '3:2' | '4:5' | '5:4' | '9:16' | '3:4' | '4:3';
	}) => {
		setLoading(true);

		const [aiResponse] = await Promise.all([
			settings.useAi.images || settings.useAi.headlines
				? dicerRequest('usm-image-generation', {
						teamId: Dicer.teamId,
						variants: settings.variants,
						userComments: settings.imagesPrompt || '',
						aspectRatio: settings.aspectRatio,
						imageStyle: 'Photo Realistic',
						withCopy: settings.useAi.headlines,
						offerId: isOtto ? Dicer.offerId.otto : Dicer.offerId.vibriance,
					}).then(res => {
						if (!res?.s3Locations?.length) throw new Error('No images generated');
						return res;
					})
				: Promise.resolve({ s3Locations: [] }),
		]).catch(() => {
			dispatch(openSnackbar({ children: 'Failed to pull AI data', severity: 'error' }));
			return [{ s3Locations: [] }];
		});

		const images: { dicerFile: string; image: string }[] = [];
		const headlines: string[] = [];
		const descriptions: string[] = [];

		if (settings.useAi.images) {
			const dicerUploadPromises: Array<Promise<{ url: string; dicerFile: string }>> = [];
			for (const dicerFile of aiResponse.s3Locations) {
				dicerUploadPromises.push(uploadFile(settings.useAi.headlines ? dicerFile.url : dicerFile));
			}

			await Promise.all(dicerUploadPromises).then(async res => {
				const maximusUploadPromises: Array<Promise<string | { url: any; dicerFile: string }>> = [];

				res.forEach(dicerResponse => {
					if (dicerResponse) {
						const { url, dicerFile } = dicerResponse as { url: any; dicerFile: string };
						maximusUploadPromises.push(uploadMaximusFile(url, dicerFile));
					}
				});

				return Promise.all(maximusUploadPromises).then(res => {
					res.forEach(maximusResponse => {
						if (maximusResponse) {
							const { url, dicerFile } = maximusResponse as { url: any; dicerFile: string };
							images.push({ dicerFile, image: url });
						}
					});
				});
			});
		}

		if (settings.useAi.headlines) {
			for (const dicerFile of aiResponse.s3Locations) {
				headlines.push(dicerFile.headline);
			}
		}

		setLoading(false);

		return { images, headlines, descriptions };
	};

	return {
		loading,
		fetchAiData,
	};
}
