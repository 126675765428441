import CreativesSearchForm from '@/Components/Pages/AddCreatives/SearchForm.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks';
import Add from '@/Icons/Add';
import Edit from '@/Icons/Edit';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import SectionTitle from '../../Components/SectionTitle.tsx';
import AddAdsSection from './AddAdsSection.tsx';
import CopyAdsButton from './CopyAdsButton.tsx';
import DisplayAdsSection from './DisplayAdsSection.tsx';
import { changeNonField } from '@/Redux/Slices/addCampaign.ts';
import Toggle from '@/Components/FormComponents/Toggle.tsx';
import { isOutbrain } from '@/utils/network-utils.ts';

enum Tab {
	AddAds,
	ViewAds,
	SearchCreatives,
}

export default function AdSetup({ isCreatives = false, campaignDetails = false }) {
	const dispatch = useAppDispatch();
	const { ads, outbrainVideoAds } = useAppSelector(state => state.addCampaign);
	const { network } = useAppSelector((state) => state.campaignDetails);
	const [tab, setTab] = useState(Tab.AddAds);

	useEffect(() => {
		if (ads.length > 0) setTab(Tab.ViewAds);
	}, [ads]);

	return (
		<div className="w-full">
			<SectionTitle
				title={isCreatives ? 'Creatives Setup' : 'Ad Setup'}
				EndAddition={
					<div className="mb-2 flex items-center gap-6">
						{isOutbrain(network) &&
							<Toggle
								label="Video Ads"
								trackColor="#4285F4"
								value={outbrainVideoAds}
								disabled={campaignDetails}
								onChange={(checked) => dispatch(changeNonField({ key: 'outbrainVideoAds', value: checked }))}
								name="outbrain-video-ads"
							/>}
						{tab !== Tab.AddAds && (
							<Button variant="contained" type="button" onClick={() => setTab(Tab.AddAds)}>
								<Add className="mr-3 size-6 fill-white" /> Add More {isCreatives ? 'Creatives' : 'Ads'}
							</Button>
						)}

						{tab !== Tab.ViewAds && (
							<Button variant="contained" type="button" onClick={() => setTab(Tab.ViewAds)}>
								<Edit className="mr-3 size-5 fill-white" /> View {isCreatives ? 'Creatives' : 'Ads'}
							</Button>
						)}

						{!isCreatives && (
							<>
								{tab !== Tab.SearchCreatives && (
									<Button variant="contained" type="button" onClick={() => setTab(Tab.SearchCreatives)}>
										Choose Gallery Creatives
									</Button>
								)}

								<CopyAdsButton />
							</>
						)}
					</div>
				}
			/>

			<main className="p-2">
				{tab === Tab.AddAds && <AddAdsSection isCreatives={isCreatives} />}
				{tab === Tab.ViewAds && <DisplayAdsSection isCreatives={isCreatives} />}
				{tab === Tab.SearchCreatives && <CreativesSearchForm />}
			</main>
		</div>
	);
}
