export function isValidUrl(url: string) {
	try {
		new URL(url)
	} catch (e) {
		return false
	}

	const pattern = new RegExp(
		'^(https?:\\/\\/)?' +
			'((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' +
			'((\\d{1,3}\\.){3}\\d{1,3}))' +
			'(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' +
			'(\\?[;&a-zA-Z\\d%_.~+={}-]*)?' +
			'(\\?[;&a-zA-Z\\d%_.~+=-]*)?' +
			'(\\#[-a-zA-Z\\d_]*)?$',
		'i'
	)

	if (url.includes('..')) return false
	return pattern.test(url)
}
