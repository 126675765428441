import {useAppDispatch, useAppSelector} from '@/hooks'
import Upload from '@/Icons/Upload'
import {changeAdSetup} from '@/Redux/Slices/addCampaign'
import {openSnackbar} from '@/Redux/Slices/main'
import { isRevContent, isTaboola, Network } from '@/utils/network-utils'
import {Checkbox, FormControlLabel} from '@mui/material'
import {processDefaultImage} from '@pqina/pintura'
import {memo, useEffect, useState} from 'react'
import FileList from './FileList'
import ImageEditor from './ImageEditor'
import {FileToUpload, validateFile} from './validation'

const getEmptyValidation = () => [] as FileToUpload[]

function UploadAdsV2() {
	const dispatch = useAppDispatch()
	const forceResize = useAppSelector(state => state.addCampaign.adSetup.forceResize)
	const currentFiles = useAppSelector(state => state.addCampaign.adSetup.filesToUpload)
	const validFilesToUpload = useAppSelector(state => state.addCampaign.adSetup.validFilesToUpload)
	const outbrainVideoAds = useAppSelector(state => state.addCampaign.outbrainVideoAds);
	const network = useAppSelector(state => state.campaignDetails.network)
	const [editingImage, setEditingImage] = useState<File>()
	const [parsedFiles, setParsedFiles] = useState(() => getEmptyValidation())

	useEffect(() => {
		const parseFiles = async () => {
			const result = getEmptyValidation()
			for (const [originalFile, file] of currentFiles) {
				const validation = await validateFile(file, network, outbrainVideoAds)
				if (validation?.error) {
					result.push({originalFile, file, error: true, reason: validation.reason})
					continue
				}
				if (validation?.reason) {
					result.push({originalFile, file, warn: true, reason: validation.reason})
					continue
				}
				result.push({originalFile, file, ok: true})
			}
			setParsedFiles(result)
			const validFiles = result.filter(item => item.ok).map(item => item.file)
			dispatch(changeAdSetup({validFilesToUpload: validFiles}))
		}
		parseFiles()
	}, [dispatch, currentFiles, network, outbrainVideoAds])

	useEffect(() => {
		return () => {
			dispatch(changeAdSetup({filesToUpload: [], validFilesToUpload: [], forceResize: true}))
		}
	}, [dispatch])

	const updateFiles = (files: [File, File][]) => {
		dispatch(changeAdSetup({filesToUpload: files}))
	}

	const appendFiles = async (fileList: FileList | null) => {
		/** Tuple representation of files state as [original, modified] */
		const files: [File, File][] = []

		for (const rawFile of Object.values(fileList || {})) {
			const file = currentFiles.some(([_, f]) => f.name === rawFile.name)
				? new File([rawFile], `${new Date().getTime()}_${rawFile.name}`, {type: rawFile.type})
				: rawFile
			const validation = await validateFile(file, network, outbrainVideoAds)
			if (validation?.autofix && !file.type.match('image/gif')) {
				const result = await processDefaultImage(file, {imageWriter: validation.autofix})
				files.push([file, result.dest])
				dispatch(
					openSnackbar({
						children: 'Some files were automatically adjusted to meet current network requirements',
						severity: 'info',
					})
				)
			} else {
				files.push([file, file])
			}
		}

		updateFiles([
			...files.filter(([_, file]) => !currentFiles.some(([_, f]) => f.name === file.name)),
			...currentFiles,
		])
	}

	const uploadInputText = new Map([
		[Network.Taboola, 'Upload Images or Videos'],
		[Network.Outbrain, outbrainVideoAds ? 'Upload Videos' : 'Upload Images'],
		[Network.RevContent, 'Upload Images'],
	]);

	return (
		<div className="relative flex w-full flex-col gap-2">
			<label
				className="flex cursor-pointer flex-col items-center justify-center rounded-md border border-dashed border-black/20 bg-neutral-200 p-1 font-semibold text-black/40 hover:brightness-105"
				onDragOver={e => void e.preventDefault()}
				onDrop={e => {
					e.preventDefault()
					e.stopPropagation()
					appendFiles(e.dataTransfer.files)
				}}
			>
				<input
					hidden
					multiple
					data-testid="fileInput"
					type="file"
					accept="video/*, image/*"
					value=""
					onChange={e => appendFiles(e.target.files)}
				/>
				<Upload className="fill-[#4285F4]"/>
				<p>{uploadInputText.get(network)}</p>
			</label>

			{(!isRevContent(network) && !outbrainVideoAds) &&
				<FormControlLabel
					className="absolute -top-9 right-1.5"
					label={`Automatically Resize (${isTaboola(network) ? '16:9' : '1.5:1'} Recommended)`}
					labelPlacement="start"
					classes={{label: 'text-sm text-black/80'}}
					control={
						<Checkbox
							size="small"
							className="-mr-1.5 p-1"
							checked={forceResize}
							onChange={() => dispatch(changeAdSetup({forceResize: !forceResize}))}
						/>
					}
				/>
			}

			<FileList items={parsedFiles} onSetEditingImage={setEditingImage}/>

			{currentFiles.length > validFilesToUpload.length && (
				<span
					className="mt-4 block rounded border border-yellow-500/30 bg-yellow-500/10 p-3 text-center text-sm font-medium text-yellow-800/80">
					Some files contain errors or warnings, and will not be uploaded. <br/>
					Please review them before proceeding.
				</span>
			)}

			{editingImage && (
				<ImageEditor
					src={editingImage}
					onClose={() => setEditingImage(undefined)}
					onProcess={res => {
						updateFiles(
							currentFiles.map(fileState => {
								const [original, file] = fileState
								if (file.name === editingImage.name) return [original, res.dest]
								return fileState
							})
						)
						setEditingImage(undefined)
					}}
				/>
			)}
		</div>
	)
}

export default memo(UploadAdsV2)
