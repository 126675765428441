import { Network } from './network-utils'

export const tokenMap = {
	[Network.Taboola]: {
		maxLength: { description: 255, headline: 255 },
		keywords: ['country', 'region', 'dma', 'city', 'platform', 'dayofweek'],
		formatter: (input: string) => `\${${input}:capitalized}$`,
	},
	[Network.Outbrain]: {
		maxLength: { description: 255, headline: 255 },
		keywords: ['country', 'region', 'city', 'dayofweek'],
		formatter: (input: string) => `\${${input}}$`,
	},
	[Network.RevContent]: {
		maxLength: { description: 250, headline: 80 },
		keywords: ['city', 'state', 'country'],
		formatter: (input: string) => `{${input.toUpperCase()}}`,
	},
}

export function formatTokens(input: string, network: Network) {
	const { formatter, keywords } = tokenMap[network]
	const regex = /{(.*?)}/g

	const errors: string[] = []
	const result = input.replace(regex, (match, token) => {
		const parsedToken = `${token}`.toLowerCase().split(':')[0]
		if (keywords.includes(parsedToken)) {
			return formatter(parsedToken)
		} else {
			errors.push(parsedToken)
			return match
		}
	})
	return { result, errors }
}
