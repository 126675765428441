import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import intersection from 'lodash/intersection';
import SectionTitle from "../../Components/SectionTitle.tsx";
import Toggle from "../../../../FormComponents/Toggle.tsx";
import Input from "../../Fields/Input.tsx";
import Select from "../../Fields/Select.tsx";
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { AddCampaignState, changeSettingsField } from '@/Redux/Slices/addCampaign.ts';
import { openSnackbar } from '@/Redux/Slices/main';
import { Network } from '@/utils/network-utils.ts';
import ChipSelect from '../../Fields/ChipSelect.tsx';
import networkRequest from '../../../../../utils/networkRequest.ts';
import {
	budgetField, ottoSection, sectionTitleNetworkDetails, splitByDeviceToggle,
	campaignNameField, conversionCapFields, cpcField,
	startDateField, siteRuleSetField, countryTargetingField,
} from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/fields.tsx';
import { pullGroupOptions, handleChipSelect } from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/functions.ts';
import {
	platformOptions, defaultGroupOptions,
	defaultAccountOptions, defaultSubAccountOptions,
} from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/consts.ts';

const osTargetingOptions: Array<{ value: string, label: string }> = [
	{ value: '0', label: 'All' },
	{ value: '1', label: 'Mac OS X' },
	{ value: '2', label: 'iOS' },
	{ value: '3', label: 'Android' },
	{ value: '4', label: 'Windows' },
];

const browserOptions: Array<{ value: string, label: string }> = [
	{ value: '0', label: 'All' },
	{ value: '1', label: 'Chrome' },
	{ value: '2', label: 'Firefox' },
	{ value: '3', label: 'Safari' },
	{ value: '4', label: 'Internet Explorer' },
	{ value: '5', label: 'Opera' },
	{ value: '6', label: 'Samsung' },
	{ value: '7', label: 'UC Browser' },
	{ value: '8', label: 'In-App' },
	{ value: '9', label: 'Edge' },
	{ value: '10', label: 'Other' },
];

const optimizationTypeOptions: Array<{ value: string, label: string }> = [
	{ value: '1', label: 'Traffic' },
	{ value: '2', label: 'CPC' },
	{ value: '3', label: 'Max Conversions' },
	{ value: '4', label: 'Target CPA' },
	{ value: '5', label: 'Target ROAS' },
];

const modifyOSTargetingOptions = (selectedPlatforms: string[]) => {
	const osOptionsByPlatform = {
		'0': ['0', '1', '2', '3', '4'],
		'1': ['0', '1', '4'],
		'2': ['0', '2', '3'],
		'3': ['0', '2', '3'],
	}
	const availableOptions = selectedPlatforms
	.flatMap(platform => osOptionsByPlatform[platform])
	.map(val => osTargetingOptions.find(opt => opt.value === val)!)
	return [...new Set(availableOptions)]
}

export const modifyOSTargetingValue = (newPlatformValue: string[], currentOSTargetingValue = [] as string[]) => {
	if (newPlatformValue.includes('0') || !newPlatformValue.length) return ['0'];
	const newOSTargetingOptions = modifyOSTargetingOptions(newPlatformValue).map(val => val.value);
	const availableOptions = intersection(newOSTargetingOptions, currentOSTargetingValue);
	if (!availableOptions.length) {
		return ['0'];
	}
	return availableOptions;
};

export default function OutbrainDetails({campaignDetails = false}: { campaignDetails?: boolean }) {
	const [groupOptionsLoading, setGroupOptionsLoading] = useState(false);
	const [groupOptions, setGroupOptions] = useState(defaultGroupOptions);
	const [accountOptionsLoading, setAccountOptionsLoading] = useState(false);
	const [accountOptions, setAccountOptions] = useState(defaultAccountOptions);
	const [subAccountOptionsLoading, setSubAccountOptionsLoading] = useState(false);
	const [subAccountOptions, setSubAccountOptions] = useState(defaultSubAccountOptions);
	const [conversionEventOptionsLoading, setConversionEventOptionsLoading] = useState(false);
	const [conversionEventOptions, setConversionEventOptions] = useState(defaultGroupOptions);
	const [accountData, setAccountData] = useState<any[]>([]);
	const [previousCPC, setPreviousCPC] = useState('0');

	const dispatch = useAppDispatch();
	const {settings, errors, isClone} = useAppSelector((state) => state.addCampaign);
	const updateSettings = <K extends keyof AddCampaignState['settings']>(
		name: K,
		value: AddCampaignState['settings'][K]
	) => {
		dispatch(changeSettingsField({ name, value }))
	}

	useEffect(() => {
		if (!campaignDetails) {
			pullGroupOptions(setGroupOptionsLoading, setGroupOptions);
		}
		if (isClone) {
			pullAccountOptions(settings.group);
			pullSubAccountOptions(settings.usmID);
		}
	}, []);

	const pullAccountOptions = (group: string) => {
		setAccountOptions(defaultAccountOptions);
		if (!group || group === '0') return;

		setAccountOptionsLoading(true);
		networkRequest('api/v1/accounts/pull', { group, network: Network.Outbrain }, 'POST')
		.then((response) => response.json())
		.then((data) => {
			if (data && typeof data === 'object' && data.length > 0) {
				setAccountData(data);
				setAccountOptions(
					[{ value: '0', label: 'Please Select' }].concat(data.map((account) => ({
						value: account.id,
						label: account.name
					})))
				);
			}
		})
		.then(() => setAccountOptionsLoading(false))
		.catch(() => setAccountOptionsLoading(false));
	}

	const pullSubAccountOptions = (usmID: string) => {
		setSubAccountOptions(defaultSubAccountOptions);
		if (usmID && usmID !== '0') {
			setSubAccountOptionsLoading(true);
			networkRequest('api/v1/accounts/sub/pull', { usm_id: usmID, network: Network.Outbrain }, 'POST')
			.then((response) => response.json())
			.then((data) => {
				if (data && typeof data === 'object' && data.length > 0) {
					setSubAccountOptions(
						[{value: '0', label: 'Please Select'}].concat(data.map((subAccount) => ({
							value: subAccount.id,
							label: subAccount.name,
						})))
					);
				}
			})
			.then(() => setSubAccountOptionsLoading(false))
			.catch(() => setSubAccountOptionsLoading(false));
		}
	}

	const pullConversionEventOptions = (usmID: string, subAccount: string) => {
		setConversionEventOptions(defaultGroupOptions);
		if (!usmID || usmID === '0' || !subAccount || subAccount === '0') return;

		setConversionEventOptionsLoading(true);
		networkRequest('api/v1/accounts/conversion/events', { usmID, subAccount })
			.then(response => response.json())
			.then(data => {
				if (!data) throw new Error();
				setConversionEventOptions([
					...defaultGroupOptions,
					...data.map(option => ({ value: option.id, label: option.name })),
				]);
			})
			.catch(() => {
				dispatch(openSnackbar({ children: 'Failed to fetch conversion events', severity: 'error' }));
			})
			.finally(() => setConversionEventOptionsLoading(false));
	};

	const elementProps = { settings, errors, updateSettings };

	return (
		<>
			{sectionTitleNetworkDetails('Outbrain Campaign Details', settings, updateSettings)}
			<div className="add-campaign-field-row">
				{campaignNameField(elementProps)}
				{!campaignDetails && (
					<>
						{siteRuleSetField(elementProps)}
						{startDateField(elementProps)}
					</>
				)}
			</div>
			<div className="add-campaign-field-row">
				{budgetField(elementProps)}
				{conversionCapFields(elementProps)}
			</div>
			<div className="mb-3"/>
			{!campaignDetails &&
				<>
					<SectionTitle title="Account Details" />
					<div className="add-campaign-field-row">
						<Select
							onChange={({ target: { value }}) => {
								updateSettings('group', value);
								updateSettings('accountId', '0');
								updateSettings('subAccountId', '0')
								updateSettings('conversionEvent', '0');
								pullAccountOptions(value);
							}}
							label="Group"
							value={settings.group}
							name="campaign-group"
							options={groupOptions}
							disabled={groupOptionsLoading}
							error={!!errors.fields.group}
							helperText={errors.fields.group}
						/>
						{settings.group !== '0' && accountOptions.length > 1 ? (
								<Select
									onChange={({target: {value}}) => {
										const usmID = accountData.filter((account: { id: string, usm_id: string }) => account.id === value)[0]?.usm_id || '';
										updateSettings('accountId', value);
										updateSettings('usmID', usmID);
										updateSettings('subAccountId', '0')
										updateSettings('conversionEvent', '0');
										pullSubAccountOptions(usmID);
									}}
									label="Outbrain Account"
									value={settings.accountId}
									name="outbrain-account"
									options={accountOptions}
									error={!!errors.fields.accountId}
									helperText={errors.fields.accountId}
								/>
							)
							: accountOptionsLoading && <div className="progress-inline-container"><CircularProgress /></div>
						}
						{settings.accountId !== '0' && subAccountOptions.length > 1 ? (
								<Select
									onChange={({target: {value}}) => {
										updateSettings('subAccountId', value);
										updateSettings('conversionEvent', '0');
										pullConversionEventOptions(settings.usmID, value);
									}}
									label="Outbrain Sub Account"
									value={settings.subAccountId}
									name="outbrain-sub-account"
									options={subAccountOptions}
									error={!!errors.fields.subAccountId}
									helperText={errors.fields.subAccountId}
								/>
							)
							: subAccountOptionsLoading && <div className="progress-inline-container"><CircularProgress /></div>
						}
					</div>
					<div className="mb-3"/>
				</>}
			<SectionTitle title="Campaign Targeting"/>
			<div className="add-campaign-field-row">
				<ChipSelect
					onChange={({ target: { value } }) => {
						const newValue = handleChipSelect(value, settings.platformTargeting);
						updateSettings('platformTargeting', newValue);
						updateSettings('osTargeting', modifyOSTargetingValue(newValue));
					}}
					label="Platform Targeting"
					value={settings.platformTargeting}
					name="platform-targeting"
					options={platformOptions}
					error={!!errors.fields.platformTargeting}
					helperText={errors.fields.platformTargeting}
				/>
				<ChipSelect
					onChange={({ target: { value} }) => updateSettings('osTargeting', handleChipSelect(value, settings.osTargeting))}
					label="OS Targeting"
					value={settings.osTargeting}
					name="os-targeting"
					options={modifyOSTargetingOptions(settings.platformTargeting)}
					error={!!errors.fields.osTargeting}
					helperText={errors.fields.osTargeting}
				/>
				{countryTargetingField(elementProps)}
			</div>
			{(!campaignDetails && settings.platformTargeting.includes('0')) &&
				splitByDeviceToggle(elementProps)}
			<div className="add-campaign-field-row">
				<ChipSelect
					onChange={({ target: { value } }) =>
						updateSettings('browserTargeting', handleChipSelect(value, settings.browserTargeting))
					}
					label="Browser Targeting"
					value={settings.browserTargeting}
					name="browser-targeting"
					options={browserOptions}
					error={!!errors.fields.browserTargeting}
					helperText={errors.fields.browserTargeting}
				/>
			</div>
			<div className="mb-3" />
			<SectionTitle
				title="Outbrain Optimizations"
				EndAddition={
					<div>
						<Toggle
							label="Exclude AdBlock Traffic"
							trackColor="#4285F4"
							value={settings.excludeAdblockTraffic}
							onChange={checked => updateSettings('excludeAdblockTraffic', checked)}
							name="exclude-adblock"
						/>
						<Toggle
							label="High Impact Targeting"
							trackColor="#4285F4"
							value={settings.highImpactTargeting}
							onChange={checked => updateSettings('highImpactTargeting', checked)}
							name="high-impact-targeting"
						/>
						<Toggle
							label="MSN Only Targeting"
							trackColor="#4285F4"
							value={settings.MSNOnlyTargeting}
							onChange={checked => updateSettings('MSNOnlyTargeting', checked)}
							name="msn-only-targeting"
						/>
					</div>}
			/>
			<div className="add-campaign-field-row">
				<Select
					onChange={({ target: { value } }) => {
						if (['3', '4', '5'].includes(value)) {
							if (settings.cpc !== '0') {
								setPreviousCPC(settings.cpc);
							}
							updateSettings('cpc', '0');
						} else {
							updateSettings('targetCPA', '0');
							updateSettings('targetROAS', '0');
							if (previousCPC && +previousCPC !== 0) {
								updateSettings('cpc', previousCPC);
								setPreviousCPC('0');
							}
						}
						if (!['4', '5'].includes(value)) {
							updateSettings('conversionEvent', '0');
						}
						updateSettings('optimizationType', value);
					}}
					label="Optimization Type"
					value={settings.optimizationType}
					name="optimizationType"
					options={optimizationTypeOptions}
					error={!!errors.fields.optimizationType}
					helperText={errors.fields.optimizationType}
				/>
				{!(['3', '4', '5'].includes(settings.optimizationType)) && cpcField(elementProps)}
				{settings.optimizationType === '4' && (
					<Input
						onChange={({ target: { value } }) => updateSettings('targetCPA', value)}
						label="Target CPA"
						value={settings.targetCPA}
						numeric
						name="campaign-targetCPA"
						startAdornment={<span>$</span>}
						error={!!errors.fields.targetCPA}
						helperText={errors.fields.targetCPA}
					/>
				)}
				{settings.optimizationType === '5' && (
					<Input
						onChange={({ target: { value } }) => updateSettings('targetROAS', value)}
						label="Target ROAS"
						value={settings.targetROAS}
						numeric
						name="campaign-targetROAS"
						endAdornment={<span>%</span>}
						error={!!errors.fields.targetROAS}
						helperText={errors.fields.targetROAS}
					/>
				)}
				{['4', '5'].includes(settings.optimizationType) &&
					(!conversionEventOptionsLoading ? (
						<Select
							onChange={({ target: { value } }) => {
								updateSettings('conversionEvent', value);
							}}
							label="Conversion Event"
							name="conversion-event"
							value={settings.conversionEvent}
							options={conversionEventOptions}
							disabled={conversionEventOptions.length < 2}
							tooltip={conversionEventOptions.length < 2 ? 'No options for current Sub Account' : ''}
							error={!!errors.fields.conversionEvent}
							helperText={errors.fields.conversionEvent}
						/>
					) : (
						<div className="progress-inline-container">
							<CircularProgress />
						</div>
					))}
			</div>
			{ottoSection(elementProps)}
		</>
	)
}
