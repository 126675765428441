import { isRevContent } from '@/utils/network-utils.ts';
import { GridColDef } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '@/hooks.ts';
import { commonColumns } from '@/utils/commonColumns.tsx';
import networkRequest from '../../../../../../utils/networkRequest.ts';
import ReportTable from '../../../../../DataTable/ReportTable.tsx';
import { CustomGridTreeDataGroupingCell } from './CustomGridTreeDataGroupingCell.tsx';
import { RoutingIcon } from './index.tsx';
import { RoutingButton } from './RoutingButton.tsx';
import { conditionalPresellCTR, visitsAndClicks } from '@/utils/conditionalColumns.tsx';

type SitesTableProps = {
	selectedImage: { id: number | string, name: string },
	aggregationModel: Record<string, any>
}

export default function SitePresellTable({ selectedImage, aggregationModel }: SitesTableProps) {
	const { campaignID, network, maximusLinks, advancedTracking } = useAppSelector(state => state.campaignDetails)
	const filterStore = useAppSelector(state => state.filters)

	const [rows, setRows] = useState<any[]>([])
	const [loading, setLoading] = useState(false)
	const expandedRows = useRef(new Set()).current

	const handleExpand = (id: string) => {
		expandedRows.has(id) ? expandedRows.delete(id) : expandedRows.add(id)
	}

	const fetchData = async (params: { ad_id?: number | string; site_id?: string, widget_id?: string }) => {
		const siteOrWidgetID = isRevContent(network) ? params.widget_id : params.site_id;
		if (!siteOrWidgetID) setLoading(true)

		const res = await networkRequest('api/v1/campaign/reporting/imagesitepresell', {
			weekDay: filterStore.weekDay,
			startDate: format(new Date(filterStore.startDate), 'yyyy-MM-dd'),
			endDate: format(new Date(filterStore.endDate), 'yyyy-MM-dd'),
			dateRange: filterStore.dateRange,
			campaignID,
			network,
			adID: params.ad_id || undefined,
			siteID: params.site_id || undefined,
			widgetID: params.widget_id || undefined,
		})
		const response = ((await res?.json()) || []) as any[]
		const mappedResponse = response.map((row, id) => {
			const groupingPath: string[] = []
			if (params.ad_id) groupingPath.push(`${isRevContent(network) ? row.widget_id : row.site_id}`)
			if (siteOrWidgetID) groupingPath.push(`${id}__${row.presell_id}`);
			return {
				...row,
				groupingPath,
				id: `${id + 1}__${groupingPath.join('__')}`,
			}
		})

		if (siteOrWidgetID) {
			setRows(prev => [...prev, ...mappedResponse])
		} else {
			setRows(mappedResponse)
		}
		setLoading(false)
	}

	useEffect(() => {
		setRows([])
		fetchData({ ad_id: selectedImage.id })
	}, [selectedImage.id, filterStore])

	const columns = [
		{
			field: 'routing',
			headerName: 'Routing',
			width: 90,
			renderCell: params => {
				return params.row.presell_url ? (
					<RoutingButton
						row={params.row}
						image={selectedImage}
						site={{ id: params.row.site_id, name: params.row.site_name }}
						widget={{ id: params.row.widget_id, name: params.row.widget_name }}
						disabled={loading}
						onSuccess={data => {
							const newRows = rows.map(row => {
								const isSameRow = row.id === data.id
								const isParentRow = isRevContent(network)
									? row.widget_id === data.widget_id && row.groupingPath.length === 1
									: row.site_id === data.site_id && row.groupingPath.length === 1
								if (isSameRow || isParentRow) {
									return { ...row, routing: !data.routing }
								}
								return row
							})
							setRows(newRows)
						}}
					/>
				) : (
					RoutingIcon(params)
				)
			},
		},
		...commonColumns([
			'epc',
			'visits',
			'clicks',
			'conversions',
			'presell_ctr',
			'spend',
			'profit',
			'profit_percent',
			'revenue',
			'cpa',
			'cvr',
			'average_cpc',
		]),
	] satisfies GridColDef[]

	const asyncColumns = useMemo(() => {
		return {
			...visitsAndClicks(true, advancedTracking, maximusLinks),
			...conditionalPresellCTR(advancedTracking, maximusLinks),
		}
	}, [advancedTracking, maximusLinks])

	return (
		<ReportTable
			treeData
			tableName={`Image/${isRevContent(network) ? 'Widget' : 'Site'}/Presell`}
			uniqueTableId={`${isRevContent(network) ? 'Widget' : 'Site'}/Presell`}
			getTreeDataPath={useCallback(row => row.groupingPath, [])}
			columns={columns}
			columnVisibility={asyncColumns}
			rows={rows}
			loading={loading}
			aggregationModel={aggregationModel}
			isGroupExpandedByDefault={useCallback(node => expandedRows.has(node.id), [expandedRows])}
			groupingColDef={{
				headerName: `${isRevContent(network) ? 'Widgets' : 'Sites'} / Presells`,
				width: 340,
				renderCell: params => (
					<CustomGridTreeDataGroupingCell
						{...params}
						adId={selectedImage.id}
						onExpand={handleExpand}
						onFetch={fetchData}
						isWidget={isRevContent(network)}
					/>
				),
			}}
			getRowClassName={params =>
				'presell_url' in params.row ? 'bg-blue-600/[.03] hover:bg-black/[.04]' : ''
			}
		/>
	)
}
