import { commonColumns } from '../../../../../utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import { useAppSelector } from '../../../../../hooks.ts';
import { conditionalCTR, visitsAndClicks } from '@/utils/conditionalColumns.tsx';
import { useMemo } from 'react';
import { estimatedReportsDisclaimer } from '@/Components/Pages/CampaignDetails/Reports/Collection/shared/helper.tsx';

const getDomain = (url: string): string => {
	try {
		if (url.includes('://p.') || url.includes('://l.')) {
			return ' - ' + url.split('.')[1];
		}
		return '';
	} catch (e) {
		return '';
	}
};

export const getOttoPresellName = (url: string): string => {
	try {
		const partsOfPresellUrl = url.split('//')[1].split('/');
		const domain = getDomain(url);
		if (partsOfPresellUrl[2]) {
			return `${partsOfPresellUrl[2].split('?')[0]}${domain}`;
		} else {
			return `${partsOfPresellUrl[1].split('?')[1]}${domain}`;
		}
	} catch (e) {
		return url;
	}
};

const columns = (isOtto: boolean, advancedTracking: boolean, maximusLinks: boolean) => [
	{
		field: 'url',
		headerName: 'Presell URL',
		width: 200,
		renderCell: (params: { value: string }) => (
			<a target="_blank" className="manage-name-link" href={params.value}>
				{isOtto ? getOttoPresellName(params.value) : params.value}
			</a>
		),
	},
	...commonColumns([
		'ctr',
		'visits',
		'clicks',
		'conversions',
		'epc',
		'spend',
		'revenue',
		'profit',
		'profit_percent',
		'cvr',
	]),
];

export default function Presells() {
	const { estimatedSpends, isOtto, advancedTracking, maximusLinks } = useAppSelector(
		state => state.campaignDetails
	);

	const asyncColumns = useMemo(() => {
		return {
			...conditionalCTR(advancedTracking, maximusLinks),
			...visitsAndClicks(true, advancedTracking, maximusLinks),
		};
	}, [advancedTracking, maximusLinks]);

	return (
		<div className="campaign-details-report">
			{!estimatedSpends && estimatedReportsDisclaimer}
			<ReportTable
				columnVisibility={asyncColumns}
				columns={columns(isOtto, advancedTracking, maximusLinks)}
				tableName="Presells"
				endpoint="api/v1/campaign/reporting/presell"
				aggregationModel={{
					visits: 'sum',
					clicks: 'sum',
					conversions: 'sum',
					cpc: 'avg',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
					cvr: 'avg',
					epc: 'avg',
					spend: 'sum',
					revenue: 'sum',
					profit: 'sum',
					profit_percent: 'profitPercent',
					presell_ctr: 'avg',
					presell_url: 'blankColumn',
				}}
			/>
		</div>
	);
}
