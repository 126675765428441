import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import networkRequest from '@/utils/networkRequest.ts';
import { defaultGroupOptions } from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/consts.ts';

export const formatDate = (date: string) => {
	try {
		if (!date) return '';
		const splitDate = date.split('-');
		if (splitDate.length !== 3) return '';
		if (splitDate[0].length !== 4) return date;
		return format(new Date(`${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`), 'yyyy-MM-dd');
	} catch (e) {
		return '';
	}
}

export const handleChipSelect = (value: string[], allValues: string[]) => {
	const newValue: string[] = [];
	if (allValues.includes('0') && value.includes('0') && value.length > 1) {
		newValue.push(...value.filter((v) => v !== '0'));
	} else if (value.includes('0') || value.length === 0) {
		newValue.push('0');
	} else {
		newValue.push(...value);
	}
	return newValue;
}

export const pullGroupOptions = (setGroupOptionsLoading: Dispatch<SetStateAction<boolean>>, setGroupOptions: Dispatch<SetStateAction<{
	value: string,
	label: string
}[]>>) => {
	setGroupOptionsLoading(true)
	networkRequest('api/v1/user/group/pull', {}, 'POST')
	.then(response => response.json())
	.then(data => {
		if (data && typeof data === 'object' && data.length > 0) {
			setGroupOptions([
				...defaultGroupOptions,
				...data.map(group => ({ value: group.id, label: group.name })),
			])
		}
	})
	.finally(() => setGroupOptionsLoading(false))
}
