import { FormControl, NativeSelect } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { changeFilter } from '@/Redux/Slices/filters.ts';

export default function NetworkFilter() {
	const network = useAppSelector((state) => state.filters.network);
	const dispatch = useAppDispatch();
	const handleChange = ({ target: { value }}) => {
		dispatch(changeFilter({ filter: 'network', value }));
	}
	return (
		<FormControl className="select-filter-form-control" variant="standard" sx={{ m: 1, minWidth: 120 }}>
			<NativeSelect
				id="network-filter"
				value={network}
				onChange={handleChange}
				label="Network"
			>
				<option value="-1">All Networks</option>
				<option value="1">Taboola</option>
				<option value="2">Outbrain</option>
				<option value="3">RevContent</option>
			</NativeSelect>
		</FormControl>
	)
}
