import { useAppDispatch, useAppSelector } from '@/hooks';
import { changeNonField } from '@/Redux/Slices/addCampaign';
import { openSnackbar } from '@/Redux/Slices/main';
import networkRequest from '@/utils/networkRequest';
import { useState } from 'react';
import { ValidateStepProps } from '../AddCampaign/Components/StepsValidation';
import { validateAds } from '../AddCampaign/Components/Validation/Ads';
import { parseCreativesPayload } from './helpers';

export function useUpdateCreatives() {
	const dispatch = useAppDispatch();
	const addCampaign = useAppSelector(state => state.addCampaign);
	const [isSavingCreatives, setIsSavingCreative] = useState(false);

	function saveCreatives(uuids: string[], options: { randomizeNewIds?: boolean } = {}) {
		const newErrors: ValidateStepProps = { fields: {} };
		for (const network of [...new Set(addCampaign.ads.map(ad => ad.network!))]) {
			newErrors.fields = {
				...newErrors.fields,
				...validateAds(addCampaign, network).fields,
			};
		}
		dispatch(changeNonField({ key: 'errors', value: newErrors }));

		const valid = !Object.keys(newErrors.fields).length;
		if (!valid) {
			const children = (
				<ul>
					{Object.keys(newErrors.fields).map((key, i) => (
						<li key={`error-${i}`}>{newErrors.fields[key]}</li>
					))}
				</ul>
			);
			dispatch(openSnackbar({ children, severity: 'error' }));
			return;
		}

		let toBeSaved = addCampaign.ads.filter(ad => uuids.includes(ad.uuid));
		if (options?.randomizeNewIds) toBeSaved = toBeSaved.map(ad => ({ ...ad, uuid: crypto.randomUUID() }));

		setIsSavingCreative(true);
		return networkRequest('api/v1/campaign/creatives/add', { creatives: parseCreativesPayload(toBeSaved) })
			.then(response => response?.json())
			.then(data => {
				if (!data?.success) throw new Error('Submit error');
				dispatch(openSnackbar({ children: 'New creatives were saved successfully!', severity: 'success' }));
				return true;
			})
			.catch(() => {
				dispatch(openSnackbar({ children: 'There was an error saving the creatives.', severity: 'error' }));
			})
			.finally(() => setIsSavingCreative(false));
	}

	return {
		isSavingCreatives,
		saveCreatives,
	};
}
