export const conversionCapOptions = [
	{ value: '0', label: 'No Cap' },
	{ value: '1', label: 'Daily' },
	{ value: '2', label: 'Weekly' },
	{ value: '3', label: 'Monthly' },
];

export const defaultGroupOptions = [
	{ value: '0', label: 'Please Select' },
];

export const defaultAccountOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'Please Select'},
];

export const defaultSubAccountOptions: Array<{ value: string, label: string }> = [
	{value: '0', label: 'Please Select'},
];

export const verticalOptions = [
	{ value: 'auto', label: 'Auto' },
	{ value: 'home', label: 'Home' },
	{ value: 'life', label: 'Life' },
	{ value: 'commercial', label: 'Commercial' },
	{ value: 'pet', label: 'Pet' },
	{ value: 'medicare', label: 'Medicare' },
];

export const platformOptions: Array<{ value: string, label: string }> = [
	{ value: '0', label: 'All' },
	{ value: '1', label: 'Desktop' },
	{ value: '2', label: 'Mobile' },
	{ value: '3', label: 'Tablet' },
];
