import IssueReport from '@/utils/reporting/IssueReport';
import {ChangeEvent, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import { FormControl, IconButton, MenuItem, Select, Menu, Typography, Tooltip } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks.ts';
import UserIcon from '../../Icons/UserIcon.tsx';
import RefreshIcon from '../../Icons/AppBar.tsx';
import HelpIcon from '../../Icons/Help.tsx';
import { refreshAll, updateRefreshedAt } from '../../Redux/Slices/filters.ts';
import './Appbar.css';

function RefreshButton() {
	const [spin, setSpin] = useState(false);
	const dispatch = useAppDispatch();
	const refresh = () => {
		dispatch(refreshAll(true));
		dispatch(updateRefreshedAt());
		setSpin(true);
		setTimeout(() => {
			setSpin(false);
		}, 2500);
	};
	return (
		<IconButton
			onClick={refresh}
			size="small"
			sx={{ ml: 1 }}
			id="refresh-button"
			disabled={spin}
		>
			<RefreshIcon
				className={`refresh-button-icon ${spin ? 'refresh-spin' : ''}`}
			/>
		</IconButton>
	);
}

function HelpPageIcon() {
	const navigate = useNavigate();
	const redirect = () => {
		navigate('/help');
	}
	return (
		<IconButton
			onClick={redirect}
			size="small"
			sx={{ ml: 1 }}
			id="help-page-button"
		>
			<HelpIcon className="help-page-button-icon" />
		</IconButton>
	);
}

function AddCampaignDropdown() {
	const navigate = useNavigate();
	const {pathname} = useLocation();
	const redirectPath = (event: ChangeEvent<{ value: unknown }>) => {
		navigate(`/add_campaign_${event.target.value}`);
	}
	return (
		<FormControl
			className={`appbar-select-form-control ${pathname.includes('add_campaign_') ? 'appbar-select-form-control-active' : ''}`}
			variant="standard" sx={{ m: 1, minWidth: 120 }}
		>
			<Select
				value="-1"
				onChange={redirectPath}
				label="User"
				name="select-add-campaign"
			>
				<MenuItem disabled value="-1">Add Campaign</MenuItem>
				<MenuItem value="taboola">Taboola</MenuItem>
				<MenuItem value="revcontent">RevContent</MenuItem>
				<MenuItem value="outbrain">Outbrain</MenuItem>
			</Select>
		</FormControl>
	);
}

function UserSettingsDropdown() {
	const navigate = useNavigate();
	const { version, branch } = useAppSelector(state => state.main)
	const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
	const open = Boolean(anchorEl);
	const isProd = process.env.NODE_ENV === 'production'
	const handleClick = (event: MouseEvent) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<IconButton
				onClick={handleClick}
				size="small"
				sx={{ ml: 2, color: 'rgba(255,255,255,0.8)', border: '2px solid rgba(255,255,255,0.8)', p: '1px' }}
				aria-controls={open ? 'user-settings-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				id="user-settings-menu-button"
			>
				<UserIcon className="user-settings-icon" />
			</IconButton>
			<Menu
				id="user-settings-menu"
				MenuListProps={{
					'aria-labelledby': 'user-settings-menu-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem divider onClick={() => navigate('/logout')}>Logout</MenuItem>
				<MenuItem dense>
					<div className='opacity-70 max-w-72'>
						<p>Maximus version: {version}</p>
						{!isProd && <Tooltip title={branch}>
							<Typography noWrap variant='inherit' className='border-t'>Branch "{branch}"</Typography>
						</Tooltip>}
					</div>
				</MenuItem>
			</Menu>
		</>
	);
}

function AppbarLink({link, render}: { link: string, render: string }) {
	const {pathname} = useLocation();
	return (
		<Link
			className={`${pathname === link ? 'text-white/100 border-white' : 'text-white/80 border-mds-neutral-10'}
			 text-lg/6 font-semibold mr-6 no-underline bg-transparent h-[calc(100%-12px)] flex items-center justify-center p-1.5 transition-all duration-[0.25s] border-b-[3px] appbar-link `}
			to={link}>{render}</Link>
	);
}


export default function Appbar({test = false}: { test?: boolean}) {
	const {isLoading} = useAuth0();
	const { pathname } = useLocation();
	const email = useAppSelector(((state) => state.main.email));
	const [hydrated, setHydrated] = useState(false);
	useEffect(() => {
		// This forces a rerender, so the date is rendered
		// the second time but not the first
		setHydrated(true);
	}, []);
	if (!hydrated) {
		// Returns null on first render, so the client and server match
		return <div></div>;
	}

	return (isLoading && !test) ? false : (
		<div className="bg-mds-neutral-10 h-[70px] max-h-[70px] w-full z-[100] p-1 inline-flex items-center justify-between overflow-hidden sticky top-0 shadow-[0_0_8px_0_rgba(0,0,0,0.5)]">
			<div className="inline-flex items-center justify-start relative">
				<Link className="bg-transparent" to="/"><img src="/maximus_logo.png" alt="maximus" className="h-[51px] w-[205px] mr-6 ml-3"/></Link>
				<AppbarLink link="/" render="Manage Campaigns" />
				<AddCampaignDropdown />
				<AppbarLink link="/creatives_gallery" render="Creatives Gallery" />

			</div>

			<div className="inline-flex items-center justify-end relative mr-3 text-white/80">
				{email}
				{!pathname.includes('add_campaign_') && <RefreshButton />}
				<IssueReport color="inherit" className="ml-2" />
				<HelpPageIcon />
				<UserSettingsDropdown />
			</div>
		</div>
	);
}
